import {
    GUARD_VERIFY,
    GUARD_SHOW_VERIFY,
} from '@/constant/constants';
import { ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS } from '@/constant/rules';
import { AUTH } from '@/plugins/AuthRules';
import { CALL_SUPPORT } from '@/plugins/CallSupportRules';

import {
    MODAL_CONFIRMATION,
    MODAL_UNAUTHORIZED,
    MODAL_LOGIN,
    MODAL_LOGOUT,
    MODAL_REGISTER,
    MODAL_PROFILE,
    MODAL_PROMOCODE,
    MODAL_WITHDRAWAL,
    MODAL_DEPOSIT,
    MODAL_SUCCESS_REGISTRATION,
    MODAL_ACCESS,
    MODAL_CASE,
    MODAL_CALL_SUPPORT,
    MODAL_IOS_INSTRUCTION,
    MODAL_VERIFICATION,
    MODAL_VIP_DOMAIN,
    MODAL_ACCOUNT_MANAGEMENT,
    MODAL_BETS_SALE,
    MODAL_DETAILING,
    MODAL_POKER_CHANGE_NICKNAME,
    CASINO_MULTI_CURRENCY_ERROR_MODAL,
    MODAL_INVEST,
    MODAL_INVEST_DONE,
    MODAL_INVEST_DIVIDENDS,
    MODAL_INVEST_ERROR,
    SOCIAL_AUTH_TELEGRAM_CONFIRMATION,
    MODAL_DEPOSIT_SUCCESS,
    MODAL_INFO_MESSAGE,
    MODAL_CASINO_CASHBACK,
    MODAL_LEADERBOARD_BONUS,
    MODAL_EXPRESS_BONUS,
    MODAL_BONUS_DEPOSIT,
    MODAL_BONUS_UNIVERSAL,
    MODAL_DEPOSIT_ERROR,
    MODAL_DEPOSIT_MESSAGE_ERROR,
    MODAL_LOCALE,
    MODAL_LOYALTY,
    MODAL_LOYALTY_EXCHANGE,
    MODAL_CANCEL_FREESPINS,
    MODAL_COUNTRY_SELECT,
} from '@desktop/constant/modalName';

export default {
    // TODO: узнать, используется ли это модальное окно
    [MODAL_CONFIRMATION]: {
        component: () => import('@desktop/modals/ConfirmationModal.vue'),
    },
    [MODAL_UNAUTHORIZED]: {
        component: () => import('@desktop/modals/UnauthorizedModal.vue'),
        meta: {
            [AUTH]: false,
        },
    },
    [MODAL_LOGIN]: {
        component: () => import('@desktop/modals/LoginModal.vue'),
        meta: {
            [AUTH]: false,
        },
    },
    [MODAL_LOGOUT]: {
        component: () => import('@desktop/modals/LogoutModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_REGISTER]: {
        component: () => import('@desktop/modals/RegistrationABModal.vue'),
        meta: {
            [AUTH]: false,
            [ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS]: true,
        },
    },
    [MODAL_PROFILE]: {
        component: () => import('@desktop/modals/ProfileModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_PROMOCODE]: {
        component: () => import('@desktop/modals/PromocodeModal.vue'),
        meta: {
            [AUTH]: true,
            [GUARD_VERIFY]: true,
        },
    },
    [MODAL_WITHDRAWAL]: {
        component: () => import('@desktop/modals/WithdrawalModal.vue'),
        meta: {
            [AUTH]: true,
            [GUARD_VERIFY]: true,
        },
    },
    [MODAL_DEPOSIT]: {
        component: () => import('@desktop/modals/DepositModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_ACCOUNT_MANAGEMENT]: {
        component: () => import('@desktop/modals/AccountManagement.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_SUCCESS_REGISTRATION]: {
        component: () => import('@desktop/modals/Register/SuccessRegistrationModal.vue'),
    },
    [MODAL_ACCESS]: {
        component: () => import('@desktop/modals/AccessModal.vue'),
    },
    [MODAL_CASE]: {
        component: () => import('@desktop/modals/Case.vue'),
    },
    [MODAL_CALL_SUPPORT]: {
        component: () => import('@desktop/modals/CallSupportModal.vue'),
        meta: {
            [CALL_SUPPORT]: true,
        },
    },
    [MODAL_IOS_INSTRUCTION]: {
        component: () => import('@desktop/modals/IOSAppInstructionModal.vue'),
    },
    [MODAL_BETS_SALE]: {
        component: () => import('@desktop/modals/BetsSaleModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_DETAILING]: {
        component: () => import('@desktop/modals/DetailingModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_VERIFICATION]: {
        component: () => import('@desktop/modals/VerificationModal.vue'),
        meta: {
            [AUTH]: true,
            [GUARD_SHOW_VERIFY]: true,
        },
    },
    [MODAL_VIP_DOMAIN]: {
        component: () => import('@desktop/modals/VipDomain.vue'),
    },
    [MODAL_POKER_CHANGE_NICKNAME]: {
        component: () => import('@desktop/modals/ChangePokerNicknameModal.vue'),
        [AUTH]: true,
    },
    [CASINO_MULTI_CURRENCY_ERROR_MODAL]: {
        component: () => import('@desktop/modals/CasinoMultiCurrencyErrorModal.vue'),
    },
    [SOCIAL_AUTH_TELEGRAM_CONFIRMATION]: {
        component: () => import('@desktop/modals/SocialAuthTelegramConfirmationModal.vue'),
    },
    [MODAL_INVEST]: {
        component: () => import('@desktop/modals/Invest/InvestModal.vue'),
    },
    [MODAL_INVEST_DONE]: {
        component: () => import('@desktop/modals/Invest/InvestModalDone.vue'),
    },
    [MODAL_INVEST_DIVIDENDS]: {
        component: () => import('@desktop/modals/Invest/InvestModalDividends.vue'),
    },
    [MODAL_INVEST_ERROR]: {
        component: () => import('@desktop/modals/Invest/InvestModalError.vue'),
    },
    [MODAL_DEPOSIT_SUCCESS]: {
        component: () => import('@desktop/modals/DepositSuccessModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_INFO_MESSAGE]: {
        component: () => import('@desktop/modals/InfoMessageModal.vue'),
    },
    [MODAL_CASINO_CASHBACK]: {
        component: () => import('@desktop/modals/Bonuses/CasinoCashbackModal.vue'),
    },
    [MODAL_LEADERBOARD_BONUS]: {
        component: () => import('@desktop/modals/Bonuses/LeaderboardBonusModal.vue'),
    },
    [MODAL_EXPRESS_BONUS]: {
        component: () => import('@desktop/modals/Bonuses/ExpressBonusModal.vue'),
    },
    [MODAL_BONUS_DEPOSIT]: {
        component: () => import('@desktop/modals/Bonuses/DepositBonusModal.vue'),
    },
    [MODAL_BONUS_UNIVERSAL]: {
        component: () => import('@desktop/modals/Bonuses/UniversalBonusModal.vue'),
    },
    [MODAL_DEPOSIT_ERROR]: {
        component: () => import('@desktop/modals/DepositErrorModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_DEPOSIT_MESSAGE_ERROR]: {
        component: () => import('@desktop/modals/DepositMessageErrorModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_LOCALE]: {
        component: () => import('@desktop/modals/LocaleModal.vue'),
    },
    [MODAL_LOYALTY]: {
        component: () => import('@desktop/modals/LoyaltyModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_LOYALTY_EXCHANGE]: {
        component: () => import('@desktop/modals/LoyaltyExchangeModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_CANCEL_FREESPINS]: {
        component: () => import('@desktop/modals/CancelFreespinsModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_COUNTRY_SELECT]: {
        component: () => import('@desktop/modals/CountrySelectModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
};
