<template lang="pug">
modal(:name="name" classes="modal-block" @before-open="beforeOpen" @before-close="beforeClose")
    .overlay(@click.prevent="close")

    .modal-body.panel(:class="modalClasses")
        .panel-header.level(v-if="title")
            span {{ title }}
            VIcon.close(v-if="closeable" icon="times" @click="close")

        .panel-block
            .level.block(v-if="additionalTitle || headerHint")
                .additional {{ additionalTitle }}
                .hint {{ headerHint }}
            slot
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';

export default {
    name: 'VModal',

    components: {
        VIcon,
    },

    props: {
        isDesktopApp: {
            type: Boolean,
            required: false,
            default: false,
        },
        closeable: {
            type: Boolean,
            required: false,
            default: true,
        },
        beforeOpen: {
            type: Function,
            required: false,
            default: () => {},
        },
        className: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        additionalTitle: {
            type: String,
            required: false,
        },
        headerHint: {
            type: String,
        },
    },
    computed: {
        modalClasses() {
            return {
                [`${this.name}-modal`]: true,
                'is-desktop-app-modal': this.isDesktopApp,
                ...this.className,
            };
        },
    },
    methods: {
        close() {
            if (this.closeable) {
                this.$emit('close');
            }
        },
        beforeClose(event) {
            if (!this.closeable) {
                event.stop();
            } else {
                this.$emit('before-close');
            }
        },
    },
};
</script>

<style scoped lang="stylus" src="./VModal.styl"></style>
