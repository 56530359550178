import { render, staticRenderFns } from "./HeaderUserDropdown.vue?vue&type=template&id=40e9a913&scoped=true&lang=pug&"
import script from "./HeaderUserDropdown.vue?vue&type=script&lang=js&"
export * from "./HeaderUserDropdown.vue?vue&type=script&lang=js&"
import style0 from "./HeaderUserDropdown.vue?vue&type=style&index=0&id=40e9a913&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e9a913",
  null
  
)

export default component.exports