<template lang="pug">
VButton.support-button(
    size="sm"
    @click="onClick"
    :rounded="rounded"
    :class="{ navigation }"
)
    VIcon(:icon="icon" :spin="spin")
</template>

<script>
import { computed } from 'vue';

import VIcon from '@/components/Icons/VIcon.vue';
import VButton from '@/components/VButton.vue';
import { useSupportChat } from '@/hooks';

import { MODAL_CALL_SUPPORT } from '@desktop/constant/modalName';

const Chat = 'chat';
const Call = 'call';

export default {
    components: {
        VButton,
        VIcon,
    },

    inject: ['$modal'],

    props: {
        type: {
            type: String,
            default: Chat,
            validator: (val) => [Chat, Call].includes(val),
        },
        rounded: Boolean,
        navigation: Boolean,
    },

    setup(props) {
        const { isLoading: isSupportChatLoading, open: supportChatOpen } = useSupportChat();

        const spin = computed(() => props.type === Chat && isSupportChatLoading.value);

        return {
            isSupportChatLoading,
            spin,
            supportChatOpen,
        };
    },

    computed: {
        icon() {
            switch (this.type) {
                case Chat:
                    return this.isSupportChatLoading ? 'spinner' : 'comment';
                case Call:
                    return 'phone';
                default:
                    throw new ReferenceError(`Unexpected button type: ${this.type}`);
            }
        },
    },

    methods: {
        onClick() {
            switch (this.type) {
                case Chat:
                    this.supportChatOpen();
                    break;
                case Call:
                    this.openModalCallSupport();
                    break;
                default:
                    throw new ReferenceError(`Unexpected button type: ${this.type}`);
            }
        },
        openModalCallSupport() {
            this.$modal.open(MODAL_CALL_SUPPORT);
        },
    },
};
</script>

<style lang="stylus" scoped>
.support-button
    display flex
    align-items center
    justify-content center
    width 24px
    height 24px
    backdrop-filter blur(13.6px)
    background-color alpha(#4e5d86, .3)
    background-image $gradient-transparent
    transition background-color 255ms, opacity 255ms
    padding 0
    min-height 24px

    &.navigation
        backdrop-filter none
        width 34px
        height 34px

        .icon-comment
            font-size 17px
            width 1em

        .icon-phone
            font-size 15px

.icon-comment
.icon-phone
    font-size 11px
</style>
