<template lang="pug">
div(:class="$style.cashbackTimer")
    div(:class="$style.cashbackImgContainer")
        img(
            :src="$options.cashbackBG"
            :class="$style.cashbackImg"
        )

    div(:class="$style.cashbackArrowIconContainer")
        VIcon(
            icon="cashback-arrow"
            :class="$style.cashbackArrowIcon"
        )

    div(:class="$style.cashbackDescription") {{ cashbackDescription }}
    div(:class="$style.cashbackText") {{ cashbackTextWide }}
</template>

<script>
import cashbackBG from '@/assets/images/cashback/cashback-circle.png';
import VIcon from '@/components/Icons/VIcon.vue';
import CashbackTimer from '@/mixins/CashbackTimer';

export default {
    components: {
        VIcon,
    },

    mixins: [CashbackTimer],

    cashbackBG,
};
</script>

<style lang="stylus" module>
.cashbackTimer
    height 24px
    display flex
    justify-content center
    align-items center
    font-size 10px
    border-radius 13px
    padding-right 10px
    background-color alpha(#4e5d86, .3)
    border-bottom-left-radius 30px
    border-top-left-radius 30px
    font-variant-numeric tabular-nums

.cashbackImgContainer
    height 28px
    width 28px
    border-radius 50%
    display flex
    align-items center
    justify-content center
    filter drop-shadow(0px 0px 8px rgba(86, 226, 91, .28))

.cashbackImg
    min-height 38px
    min-width 34px
    margin-bottom 11px
    margin-left 5px

.cashbackArrowIconContainer
    align-self stretch
    overflow hidden
    display flex
    align-items center
    margin-left 6px

.cashbackArrowIcon
    height 13px
    width 7px
    margin-right auto
    position relative
    animation arrow-icon 5s infinite ease

.cashbackDescription
    color alpha(#ffffff, .5)
    margin-left 6px
    white-space nowrap

.cashbackText
    margin-left 5px
    white-space nowrap
    color $white
    font-weight 600
    text-align left

@keyframes arrow-icon
    0%
        top -25px
        opacity 0
    10%
        top 0px
        opacity 1
    80%
        top 0px
        opacity 1
    90%
        top 25px
        opacity 0
    100%
        top 25px
        opacity 0
</style>
