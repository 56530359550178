<template lang="pug">
section.base-info
    .base-info__header
        .base-info__title {{ title }}
        a.base-info__link(
            v-if="linkText"
            @click="$emit('link-click')"
        ) {{ linkText }}
    .base-info__content {{ message }}
</template>

<script>
import VButton from '@/components/VButton.vue';

export default {
    components: {
        VButton,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="stylus" scoped>
.base-info
    padding 10px 15px
    min-width 220px

    .base-info__header
        display flex
        align-items center
        justify-content space-between
        margin-bottom 5px

        .base-info__title
            color #000
            font-size 13px
            font-weight bold

        .base-info__link
            display block
            color $primary
            text-align right
            padding-left 10px
            font-weight 600

    .base-info__content
        margin-top 10px
        color #000
        font-size 12px
        font-weight 400
        width 190px
        white-space normal
</style>
