<template lang="pug">
.header-desktop-apps
    .text
        .bold {{ $store.getters.lang.header.app }}
        div(v-if="isApple") {{ $store.getters.lang.header['app-mac'] }}
        div(v-else) {{ $store.getters.lang.header['app-windows'] }}
    PWAButton.app-icon(small)
</template>
<script>
import PWAButton from '@desktop/components/PWAButton.vue';

export default {
    name: 'HeaderDesktopApps',

    components: {
        PWAButton,
    },

    computed: {
        isApple() {
            return this.$platform.desktop.apple.mac;
        },
    },
};
</script>

<style scoped lang="stylus">
.header-desktop-apps
    display flex
    align-items center
    .text
        font-family $font-family-text
        font-size 10px
        font-weight normal
        line-height 1.3
        text-align right
        color alpha(#fff, .5)
        .bold
            color white
            font-weight 600
    .app-icon
        margin-left 7px
        :deep(.pwa-icon)
            width 24px
            height 24px
</style>
