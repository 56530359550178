/**
 * Directive saves scrollTop position for component based on routePath.
 * Directive applies only after browser "back"/"forward" buttons clicks (router.isPopstateAction is true).
 * You should specify unique directive value (preferable component.name).
 * Usage example:
 *      BetsContentLayout(
 *          v-scroll-remember="$options.name"
 *      )
 */

class ScrollRemember {
    constructor() {
        this.elementToScrollMap = new Map();
    }

    saveScroll(DOMElement, uniqueElementId, path) {
        this.elementToScrollMap.set(ScrollRemember.getMapKey(uniqueElementId, path), DOMElement.scrollTop);
    }

    applyScroll(DOMElement, uniqueElementId, path) {
        const scrollTopGoal = this.elementToScrollMap.get(ScrollRemember.getMapKey(uniqueElementId, path)) || 0;

        // Due to lazy scroll we should try to scroll many times
        let spentAttempts = 0;
        function scrollToGoalAttemptFnc() {
            spentAttempts += 1;
            DOMElement.scrollTop = scrollTopGoal;
            const actualScrollTop = DOMElement.scrollTop;
            if (actualScrollTop < scrollTopGoal && spentAttempts < ScrollRemember.APPLY_SCROLL_MAX_ATTEMPTS) {
                setTimeout(scrollToGoalAttemptFnc, ScrollRemember.APPLY_SCROLL_INTERVAL);
            }
        }
        scrollToGoalAttemptFnc();
    }
}
ScrollRemember.getMapKey = (uniqueId, path) => `${uniqueId}__${path}`;
ScrollRemember.APPLY_SCROLL_MAX_ATTEMPTS = 300;
ScrollRemember.APPLY_SCROLL_INTERVAL = 20;

export default (router) => {
    const scrollRemember = new ScrollRemember();

    /**
     * We use it because in hooks current route is "next" route path.
     * But we should use route path "from".
     * And we update this variable in hooks "inserted", "componentUpdated".
     */
    function updateElementPathToSaveScrollFor(element, path) {
        element.pathToSaveScrollFor = path;
    }

    function getElementPathToSaveScrollFor(element) {
        return element.pathToSaveScrollFor;
    }

    return {
        inserted(element, binding) {
            updateElementPathToSaveScrollFor(element, router.history.current.path);
            if (router.isPopstateAction) {
                scrollRemember.applyScroll(element, binding.value, router.history.current.path);
            }
        },

        componentUpdated(element, binding) {
            // If component is updated with the same routePath we should do nothing
            if (getElementPathToSaveScrollFor(element) === router.history.current.path) return;

            scrollRemember.saveScroll(element, binding.value, getElementPathToSaveScrollFor(element));
            updateElementPathToSaveScrollFor(element, router.history.current.path);
            if (router.isPopstateAction) {
                scrollRemember.applyScroll(element, binding.value, router.history.current.path);
            } else {
                element.scrollTop = 0;
            }
        },

        unbind(element, binding) {
            scrollRemember.saveScroll(element, binding.value, getElementPathToSaveScrollFor(element));
        },
    };
};
