<template>
  <div :class="$style.root">
    <HeaderUserBalance />

    <HeaderUserDropdown />

    <VButton
      type="green"
      :class="$style.buttonDeposit"
      @click="openModalDeposit"
    >
      <span>{{ text['user-box'].deposit }}</span>
    </VButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import VButton from '@/components/VButton.vue';

import { MODAL_DEPOSIT } from '@desktop/constant/modalName';
import HeaderUserBalance from '@desktop/layouts/components/Header/HeaderUserBalance.vue';
import HeaderUserDropdown from '@desktop/layouts/components/Header/HeaderUserDropdown.vue';

export default {
    name: 'HeaderUserMenu',

    components: {
        HeaderUserBalance,
        HeaderUserDropdown,
        VButton,
    },

    inject: ['$modal'],

    computed: {
        ...mapGetters([
            'showVipDomain',
            'currency',
            'balance',
            'showBalance',
            'user',
        ]),

        text() {
            return this.$store.getters.lang.header;
        },
    },
    methods: {
        openModalDeposit() {
            this.$modal.open(MODAL_DEPOSIT, {
                closeOnBackgroundClick: false,
            });
        },
    },
};
</script>

<style lang="stylus" module>
@import '~@/styles/animations.styl'

.root
    display flex

.buttonDeposit
    min-width 134px
    overflow hidden
    position relative
    font-weight 500
    font-size 13px
    line-height 18px
    letter-spacing -.15px

    &::before
        content ''
        position absolute
        top calc(50% - 62px)
        width 92px
        height 92px
        background-image radial-gradient(19.58% 37.96% at 16.68% 41.55%, alpha(#fff, 0.6) 0%, alpha(#fff, 0) 100%)

    &::before :global
        animation flare-2 5s infinite ease-in-out
</style>
