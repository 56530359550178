<template lang="pug">
.currency-dropdown
    VDropdown(
        :align="align"
        hoverable
    )
        .currency-dropdown-icon(slot="trigger")
            VIcon(icon="dots")
        VDropdownItem(
            v-for="it in items"
            :key="it.text"
            nowrap
            @click="it.click"
        )
            span {{ it.text }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';
import { VDropdown, VDropdownItem } from '@/components/VDropdown/index';
import { CURRENCY_VALIDATOR } from '@/constant/currency';

import {
    MODAL_DEPOSIT,
    MODAL_WITHDRAWAL,
} from '@desktop/constant/modalName';

export default {
    name: 'CurrencyActionDropdown',

    components: {
        VDropdown,
        VDropdownItem,
        VIcon,
    },

    inject: ['$modal'],

    props: {
        currency: {
            type: String,
            validator: CURRENCY_VALIDATOR,
            required: true,
        },

        align: {
            type: String,
            default: 'right',
        },
    },

    computed: {
        ...mapGetters({
            lang: 'lang',
            currentCurrency: 'currency',
        }),

        text() {
            return this.lang.CurrencyActionDropdown;
        },

        items() {
            return [
                ...this.currentCurrency === this.currency
                    ? []
                    : [{ text: this.text.makePrimary, click: this.makePrimary }],
                { text: this.text.withdrawal, click: this.withdrawal },
                { text: this.text.deposit, click: this.deposit },
            ];
        },
    },

    methods: {
        ...mapActions([
            'setCurrency',
        ]),

        async makePrimary() {
            await this.setCurrency(this.currency);
        },

        withdrawal() {
            this.$modal.open(MODAL_WITHDRAWAL, {
                props: {
                    currencyDefault: this.currency,
                },
                closeOnBackgroundClick: false,
            });
        },

        deposit() {
            this.$modal.open(MODAL_DEPOSIT, {
                props: {
                    currencyDefault: this.currency,
                },
                closeOnBackgroundClick: false,
            });
        },
    },
};
</script>

<style lang="stylus" scoped>

.currency-dropdown-icon
    color #1f2531
    margin-right 5px
    padding 5px 10px
</style>
