import { render, staticRenderFns } from "./MatchTranslation.vue?vue&type=template&id=ad7708dc&scoped=true&lang=pug&"
import script from "./MatchTranslation.vue?vue&type=script&lang=js&"
export * from "./MatchTranslation.vue?vue&type=script&lang=js&"
import style0 from "./MatchTranslation.vue?vue&type=style&index=0&id=ad7708dc&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad7708dc",
  null
  
)

export default component.exports