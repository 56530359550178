/**
 * @callback Callback
 * @param {Any} curr - текущее значение аргумента
 * @param {Any} prev - предыдущее значение аргумента
 * @param {Element} el - елемент, на котором сработала директива
 * @param {VNode} vnode
 */

/**
 * Директива отслеживает изменение аргумента и вызывает функцию,
 * переданную в качестве значения, с новым и старыем значением аргумента
 *
 * @example
 * v-watch:[argument].immediate="callback"
 *
 * @param {Any} argument - аргумент, за которым нужно следить
 * @param {boolean} [immediate] - немедленный вызов callaback
 * @param {Callback} callback - функция, вызываемая при изменении аргумента
 */
export default {
    bind(el, binding) {
        if (binding.modifiers.immediate) {
            binding.value(binding.arg, binding.oldArg, el, null);
        }
    },

    update(el, binding, vnode) {
        if (binding.arg === binding.oldArg) return;
        binding.value(binding.arg, binding.oldArg, el, vnode);
    },
};
