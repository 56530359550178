import VueRouter from 'vue-router';

import {
    checkRoutesForReducedFunctionalityInvest,
    checkRoutesForReducedFunctionalityPoker,
} from '@/router/guards';
import mainGuard from '@/router/guards/main';
import popstateEventController from '@/router/utils/popstateEventController';
import { openLoaderOnOAuthPage } from '@/utils/globalLoader';

import ROUTE_NAME from '@desktop/constant/routeName';

import routes from './routes';

/**
 * @param {import('vuex').Store} store
 * @returns {VueRouter}
 */
export function createRouter(store) {
    const router = new VueRouter({
        routes,
        mode: 'history',
        linkActiveClass: 'active',
        scrollBehavior(to, from, savedPosition) {
            if (to.name === from.name) {
                return savedPosition ?? null;
            }

            return savedPosition ?? { x: 0, y: 0 };
        },
    });

    router.beforeEach((to, from, next) => {
        mainGuard(to, from, next, router, store);
    });

    popstateEventController.registerRouterBeforeEachHook(router);

    router.beforeEach((to, from, next) => {
        checkRoutesForReducedFunctionalityPoker({
            to,
            next,
            store,
            routePoker: { name: ROUTE_NAME.POKER_GAME },
        });
    });

    router.beforeEach((to, from, next) => {
        checkRoutesForReducedFunctionalityInvest({
            to,
            next,
            store,
            routeInvest: { name: ROUTE_NAME.INVEST },
        });
    });

    router.beforeEach((to, from, next) => {
        openLoaderOnOAuthPage(to, ROUTE_NAME.OAUTH);
        next();
    });

    return router;
}
