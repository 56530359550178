<template lang="pug">
div(:class="$style.container")
    div(:class="$style.text")
        div(:class="$style.title") {{ text.title }}
        div(:class="$style.subtitle") {{ text.header.subtitle1 }}

    RulesSupportButton(
        v-if="supportNumber"
        type="call"
        :class="$style.call"
    )
    RulesSupportButton(type="chat")
</template>

<script>
import { mapGetters } from 'vuex';

import RulesSupportButton from '@desktop/pages/components/Rules/RulesSupportButton.vue';

export default {
    components: {
        RulesSupportButton,
    },
    computed: {
        ...mapGetters([
            'lang',
            'supportNumber',
        ]),
        text() {
            return this.lang.rules;
        },
    },
};
</script>

<style lang="stylus" module>
.container
    display flex

.call
    margin-right 5px

.text
    text-align right
    margin-right 10px
    font-size 10px

.title
    font-weight 600
    line-height 1.3

.subtitle
    line-height 1
    color alpha(#fff, .5)
</style>
