import { PROVIDER_XBET } from '@/constant/constants';

import ROUTE_NAME from '@desktop/constant/routeName';

export default {
    namespaced: true,

    getters: {
        sportResultList(...[,,, rootGetters]) {
            return rootGetters['results/resultSportList'].map((sport) => ({
                id: sport.id,
                providerId: PROVIDER_XBET,
                name: sport.name,
                matchCount: sport.matchCount,
                sportTagKey: sport.sportTagKey,
                iconUrl: sport.iconUrl,
            }));
        },

        sportListAll(_, getters, rootState, rootGetters) {
            return (service) => rootGetters[`xbet/${service}/sportList`]
                .sort(({ order: a }, { order: b }) => a.menu - b.menu)
                .map((sport) => ({
                    ...sport,
                    id: +sport.id,
                    matchCount: getters.sportMatchCount(sport, service),
                    rootRouteName: ROUTE_NAME.BETS,
                    routeParams: { service },
                }));
        },

        sportMatchCount(_, __, rootState) {
            return (sport, service) => rootState.xbet[service].matchCountBySportId[sport.id];
        },

        sportListCybersport(state, getters, rootState, rootGetters) {
            return (service) => rootGetters[`xbet/${service}/sportListCybersport`]
                .map((sport) => ({
                    ...sport,
                    id: +sport.id,
                    matchCount: getters.sportMatchCount(sport, service),
                    rootRouteName: ROUTE_NAME.BETS_CYBERSPORT,
                    routeParams: { service },
                }));
        },

        sportCategories(_, __, rootState, rootGetters) {
            return (sport, service) => rootGetters[`xbet/${service}/getCategoryListBySportIdFiltered`](sport.id)
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort(({ isTopSport: a }, { isTopSport: b }) => (b ? 1 : 0) - (a ? 1 : 0));
        },

        categoryTournaments(_, __, rootState, rootGetters) {
            return (category, service) => rootGetters[`xbet/${service}/getTournamentListByCategoryId`](category.id)
                .map((tournament) => ({
                    ...tournament,
                    id: +tournament.id,
                    categoryId: +tournament.categoryId,
                }))
                .sort((a, b) => a.isEsport - b.isEsport);
        },
    },
};
