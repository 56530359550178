<template lang="pug">
.pwa(:class="{ small }")
    .pwa-container(@click="onClick")
        .pwa-button
            .pwa-button-title {{ os }}
            .pwa-button-text {{ $store.getters.lang.button.pwa }}
        VIcon.pwa-icon(:icon="icon")
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';
import pwa from '@/plugins/InstallPWA';

export default {
    name: 'PwaButton',

    components: {
        VIcon,
    },

    props: {
        small: Boolean,
    },

    computed: {
        isApple() {
            return this.$platform.desktop.apple.mac;
        },

        os() {
            return this.isApple ? 'Mac OS' : 'Windows';
        },

        icon() {
            return this.isApple ? 'apple' : 'windows';
        },
    },

    methods: {
        onClick() {
            this.$emit('click');
            pwa.popup();
        },
    },
};
</script>

<style lang="stylus" scoped>
.pwa-button
    background-image linear-gradient(112deg, #108de7 -35%, #0855c4 99%)
    border-radius 8px
    height 35px
    width 134px
    transform translateZ(0)
    overflow hidden

    .small &
        display none

    &:hover::before
        left calc(100% + 15px)
        transition .7s ease

    &::before
        background-color alpha(#fff, .2)
        bottom 0
        content ''
        left -45px
        position absolute
        top 0
        transform skewX(-20deg)
        width 30px
        will-change left
        z-index 1

.pwa-container
    position relative
    cursor pointer

.pwa-button
    padding 4px 0 0 10px

.pwa-icon
    position absolute
    width 39px
    height 39px
    right 10px
    top -2px

    .small &
        position relative
        top 0
        right 0
        width 30px
        height 30px

.pwa-button-title
    font-size 13px
    font-weight 600

.pwa-button-text
    opacity 0.5
    font-size 10px
</style>
