<template lang="pug">
div(
    v-resize="onResize"
    :class="$style.wrapper"
)
    slot(:updateFluidCover="updateFluidCover")
    div(
        :class="$style.cover"
        :style="coverStyleVariablesObject"
    )
        slot(name="cover")
</template>

<script>
import { getElementRelativeOffset, isElementInViewport } from '@/utils/dom';
import { waitForRender } from '@/utils/waitForRender';

const ANIMATION_DURATION = 500;
const ANIMATION_DURATION_CSS = `${ANIMATION_DURATION / 1000}s`;

const coverStateEnum = Object.freeze({
    STAGE_APPEARING_BEFORE: 'appearing before',
    STAGE_APPEARING_AFTER: 'appearing after',
    STAGE_DISAPPEARING_BEFORE: 'disappearing before',
    STAGE_DISAPPEARING_AFTER: 'disappearing after',
    STAGE_TRANSFORM_BEFORE: 'transform before',
    STAGE_TRANSFORM_AFTER: 'transform after',
});

export default {
    props: {
        /** Если указано, то элемент "cover" будет сжиматься/удлиняться по высоте.
         *  Зато вся анимация будет проходить на GPU.
         * */
        gpuScalingInitialHeight: {
            type: Number,
            default: null,
        },
        /** Если указано, то элемент "cover" будет сжиматься/удлиняться по ширине.
         *  Зато эта анимация будет проходить на GPU.
         * */
        gpuScalingInitialWidth: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            stage: coverStateEnum.STAGE_DISAPPEARING_AFTER,
            elementOffsetInWrapperLeft: null,
            elementOffsetInWrapperTop: null,
            elementUnderCoverHeight: null,
            elementUnderCoverWidth: null,
            isAnimationDisabled: false,
        };
    },

    computed: {
        useVerticalGPUScaling() {
            return this.gpuScalingInitialHeight != null;
        },

        useHorizontalGPUScaling() {
            return this.gpuScalingInitialWidth != null;
        },

        cssVariableHeight() {
            const height = this.useVerticalGPUScaling ? this.gpuScalingInitialHeight : this.elementUnderCoverHeight;
            return height ? `${height}px` : undefined;
        },

        cssVariableWidth() {
            const width = this.useHorizontalGPUScaling ? this.gpuScalingInitialWidth : this.elementUnderCoverWidth;
            return width ? `${width}px` : undefined;
        },

        cssVariableOpacity() {
            return [
                coverStateEnum.STAGE_APPEARING_BEFORE,
                coverStateEnum.STAGE_DISAPPEARING_AFTER,
            ].includes(this.stage) ? 0 : 1;
        },

        cssVariableTransform() {
            if (this.elementOffsetInWrapperLeft === null || this.elementOffsetInWrapperTop === null) {
                return null;
            }
            let transform = `translate(${this.elementOffsetInWrapperLeft}px, ${this.elementOffsetInWrapperTop}px)`;
            if (this.useHorizontalGPUScaling) {
                transform += ` scaleX(${this.elementUnderCoverWidth / this.gpuScalingInitialWidth})`;
            }
            if (this.useVerticalGPUScaling) {
                transform += ` scaleY(${this.elementUnderCoverHeight / this.gpuScalingInitialHeight})`;
            }
            return transform;
        },

        cssVariableTransition() {
            if (this.isAnimationDisabled) {
                return null;
            }

            if (
                [
                    coverStateEnum.STAGE_APPEARING_BEFORE,
                    coverStateEnum.STAGE_DISAPPEARING_BEFORE,
                ].includes(this.stage)
            ) {
                return null;
            }

            let transition = `opacity ${ANIMATION_DURATION_CSS}`;

            if ([coverStateEnum.STAGE_APPEARING_AFTER, coverStateEnum.STAGE_DISAPPEARING_AFTER].includes(this.stage)) {
                return transition;
            }

            transition += `, transform ${ANIMATION_DURATION_CSS}`;

            if (!this.useVerticalGPUScaling) {
                transition += `, height ${ANIMATION_DURATION_CSS}`;
            }
            if (!this.useHorizontalGPUScaling) {
                transition += `, width ${ANIMATION_DURATION_CSS}`;
            }

            return transition;
        },

        coverStyleVariablesObject() {
            return {
                '--fluid-cover-height': this.cssVariableHeight,
                '--fluid-cover-width': this.cssVariableWidth,
                '--fluid-cover-opacity': this.cssVariableOpacity,
                '--fluid-cover-transition': this.cssVariableTransition,
                '--fluid-cover-transform': this.cssVariableTransform,
            };
        },
    },

    created() {
        this.lastUpdateParams = [];
        window.addEventListener('orientationchange', this.onResize);
    },

    beforeDestroy() {
        window.removeEventListener('orientationchange', this.onResize);
    },

    methods: {
        async updateFluidCover(domElementUnderCover, forceHideCover) {
            const hideCover = !domElementUnderCover || forceHideCover;

            if (hideCover && this.stage === coverStateEnum.STAGE_DISAPPEARING_AFTER) {
                return;
            }

            this.isAnimationDisabled = false;
            if (hideCover) {
                this.stage = coverStateEnum.STAGE_DISAPPEARING_BEFORE;
                if (
                    domElementUnderCover
                    && domElementUnderCover === this.lastUpdateParams[0]
                    && !isElementInViewport(domElementUnderCover)
                ) {
                    /* отключаем анимацию при скрытии облошки,
                       если до этого она была над этим же самым элементом, но элемент стал невидимым */
                    this.isAnimationDisabled = true;
                }
            } else if (this.stage === coverStateEnum.STAGE_DISAPPEARING_AFTER) {
                this.stage = coverStateEnum.STAGE_APPEARING_BEFORE;
            } else {
                this.stage = coverStateEnum.STAGE_TRANSFORM_BEFORE;
            }

            this.lastUpdateParams = [domElementUnderCover, hideCover];

            const tempInnerStage = this.stage;

            if (domElementUnderCover && tempInnerStage !== coverStateEnum.STAGE_DISAPPEARING_BEFORE) {
                const elementOffsetInWrapper = getElementRelativeOffset(domElementUnderCover, this.$el);

                this.elementOffsetInWrapperLeft = elementOffsetInWrapper.left;
                this.elementOffsetInWrapperTop = elementOffsetInWrapper.top;

                this.elementUnderCoverHeight = domElementUnderCover.offsetHeight;
                this.elementUnderCoverWidth = domElementUnderCover.offsetWidth;
            }

            await waitForRender();

            switch (tempInnerStage) {
                case coverStateEnum.STAGE_DISAPPEARING_BEFORE:
                    this.stage = coverStateEnum.STAGE_DISAPPEARING_AFTER;
                    break;
                case coverStateEnum.STAGE_APPEARING_BEFORE:
                    this.stage = coverStateEnum.STAGE_APPEARING_AFTER;
                    break;
                default:
                    this.stage = coverStateEnum.STAGE_TRANSFORM_AFTER;
            }
        },

        onResize() {
            this.updateFluidCover(...this.lastUpdateParams);
        },
    },
};
</script>

<style lang="stylus" module>
.wrapper
    height 100%
    width 100%
    position relative

.cover
    position absolute
    user-select none
    pointer-events none
    top 0
    left 0
    height var(--fluid-cover-height, 0px)
    width var(--fluid-cover-width, 0px)
    opacity var(--fluid-cover-opacity, 0)
    transition var(--fluid-cover-transition)
    transform var(--fluid-cover-transform)
    transform-origin left top
</style>
