export const CALL_SUPPORT = Symbol('Call support rule');

/**
 * @param callSupportRule {Boolean}
 * @param store {Store}
 * @returns {Boolean}
 */
export const checkCallSupportAllowed = (callSupportRule, store) => {
    if (callSupportRule === undefined) {
        return true;
    }

    return typeof callSupportRule === 'boolean' && store.rootGetters.supportNumber;
};
