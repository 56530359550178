<template lang="pug">
.header-auth
    .header-auth__item
        VButton.header-button.login(
            type="secondary"
            class-name="df-aic-jcc"
            @click="openModalLogin"
        )
            span {{ text['login-box'].login }}

    .header-auth__item
        VButton.header-button(
            type="green"
            @click="openModalRegister"
            class-name="df-aic-jcc"
            icon="plus"
        )
            span {{ registrationButtonText }}
</template>

<script>
import VButton from '@/components/VButton.vue';
import { AppStorage, REGISTER_FORM } from '@/plugins/AppStorage';

import { MODAL_LOGIN, MODAL_REGISTER } from '@desktop/constant/modalName';

export default {
    components: {
        VButton,
    },

    inject: ['$modal'],

    computed: {
        text() {
            return this.$store.getters.lang.header;
        },

        registrationButtonText() {
            return AppStorage.reactiveStorageObject[REGISTER_FORM]
                ? this.text.completeRegistration
                : this.text.registration;
        },
    },

    methods: {
        openModalLogin() {
            this.$modal.open(MODAL_LOGIN);
        },

        openModalRegister() {
            this.$modal.open(MODAL_REGISTER);
        },
    },
};
</script>

<style lang="stylus" scoped>
.header-auth
    display flex

.header-auth__item
    &:not(:last-child)
        margin-right 10px

.header-button
    // Фикс для сафари, 0 пикселей - не фиксит :S
    transform translateZ(1px)
</style>
