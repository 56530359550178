<template lang="pug">
KeepAlive(exclude="home-page,live-page,prematch-page")
    RouterView
</template>

<script>
export default {
    name: 'BetsSubLayout',
};
</script>
