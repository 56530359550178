<template lang="pug">
.header__line--top(:class="headerClass")
    .header-line-left
        .level-item(v-if="!isDesktopApp")
            VButton.ttn.header-button.df-aic-jcc(
                type="mini-icon"
                @click="openModalAccess"
            )
                VIcon.access-icon(
                    icon="lock"
                    inline
                )
            VButton.ttn.header-button(
                v-if="!isPwa"
                class-name="df-aic-jcc"
                type="mini-icon"
                @click="goToMobile"
            )
                VIcon.access-icon(
                    icon="mobile"
                    inline
                )
        .divider
        FreeMoneyLink
    .header-line-right
        .header-line-right--item
            .df
                BonusLink(v-if="!isReducedFunctionalityPoker")
                template(v-if="isAuthed && cashbackAllowed")
                    .spacer-l
                    VDropdown(
                        angle
                        hoverable
                        align="center"
                    )
                        CashbackTimer(slot="trigger")
                        CashbackInfo

        .header-line-right--item(v-if="isHeaderDesktopAppsBlockShown")
            .df
                HeaderDesktopApps
                .spacer
                HeaderMobileApps(:positionTriangleRight="!isShowChangeLanguage")

        .header-line-right--item(v-if="isShowChangeLanguage")
            ChangeLanguage

        .header-line-right--item(v-if="isCasinoGamePage")
            HeaderSupportPanel
</template>
<script>
import { mapGetters } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';
import VButton from '@/components/VButton.vue';
import { VDropdown } from '@/components/VDropdown/index';
import {
    APP_MODE_MOBILE,
    HEADER_CASINO_GAME,
    HEADER_INVEST,
    HEADER_LEADERBOARD,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
} from '@/constant/constants';
import { open } from '@/plugins/accessTimestamp';
import { APP_MODE, AppStorage } from '@/plugins/AppStorage';

import FreeMoneyLink from '@desktop/components/FreeMoney/FreeMoneyLink.vue';
import CashbackInfo from '@desktop/components/Info/CashbackInfo.vue';
import BonusLink from '@desktop/components/Navigation/BonusLink.vue';
import ChangeLanguage from '@desktop/components/Navigation/ChangeLanguage.vue';
import CashbackTimer from '@desktop/components/Timers/CashbackTimer.vue';
import { MODAL_ACCESS } from '@desktop/constant/modalName';
import HeaderSupportPanel from '@desktop/layouts/components/SupportPanel/HeaderSupportPanel.vue';

import HeaderDesktopApps from './apps/HeaderDesktopApps.vue';
import HeaderMobileApps from './apps/HeaderMobileApps.vue';

export default {
    name: 'HeaderTop',
    components: {
        VButton,
        HeaderDesktopApps,
        HeaderMobileApps,
        ChangeLanguage,
        FreeMoneyLink,
        CashbackTimer,
        CashbackInfo,
        BonusLink,
        VDropdown,
        HeaderSupportPanel,
        VIcon,
    },

    inject: ['$modal'],

    computed: {
        ...mapGetters([
            'isDesktopApp',
            'isPwaIgnored',
            'isShowChangeLanguage',
            'cashbackAllowed',
            'isReducedFunctionalityPoker',
            'isAuthed',
        ]),

        headerClass() {
            return {
                'invest-theme': this.isInvestPage,
                'leaderboard-theme': this.isLeaderboardPage,
            };
        },

        isInvestPage() {
            return this.customHeader === HEADER_INVEST;
        },

        isLeaderboardPage() {
            return this.customHeader === HEADER_LEADERBOARD;
        },

        isCasinoGamePage() {
            return this.customHeader === HEADER_CASINO_GAME;
        },

        customHeader() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_HEADER_BOTTOM];
        },

        isPwa() {
            return this.$platform.pwa.any;
        },

        isHeaderDesktopAppsBlockShown() {
            return !this.isPwaIgnored && !this.isDesktopApp && !this.isPwa;
        },
    },

    methods: {
        openModalAccess() {
            open(() => {
                this.$modal.open(MODAL_ACCESS, { toQueue: true });
            }, 0);
        },

        goToMobile() {
            AppStorage.set(APP_MODE, APP_MODE_MOBILE);
            window.location.reload();
        },
    },
};
</script>
<style scoped lang="stylus">
@import '~@desktop/styles/mixins.styl'

.header__line--top
    container(15px)
    display flex
    align-items center
    height 52px
    background-color #090f1e
    position relative

    &.leaderboard-theme
        background-color #01061a

    &.invest-theme
        background-color transparent

    .level-item:not(:last-child)
        margin-right $size-xl

    .spacer
        display inline-block
        margin 0 5px

    .spacer-l
        display inline-block
        margin 0 8px

    .separator
        display inline-block
        margin 0 10px
        width 1px
        height 14px
        opacity 0.2
        background white

    & :deep(.header-button)
        min-height 0
        height 24px
        width 24px
        padding 0
        margin-right 8px
        display flex
        justify-content center
        align-items center

        &:last-of-type
            margin-right 0

    .access-icon,
    .access2-icon
        font-size 14px

    .header-line-left
        display flex
        align-items center
        flex 1

    .divider
        background-color #fff
        opacity .2
        width 1px
        height 14px
        margin 0 12px 0 0

    .header-line-right
        display flex
        align-items center

        .header-line-right--item
            &:not(:last-child)
                margin-right 21px
                position relative

                &::before
                    content ''
                    display block
                    height 14px
                    width 1px
                    background alpha($white, 0.2)
                    position absolute
                    right -10px
                    top calc(50% - 7px)

    .level-item
        &:not(:last-child)
            margin-right $size-md

</style>
