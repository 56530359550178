import Vue from 'vue';
/**
 *    Иногда нам нужно ввызвать какой-то код после того как произойдут изменения в DOM.
 *    Например, для прямой манипуляции.
 *    Чтобы не полагаться на setTimeout и сократить бойлерплейт – используй эту функция.
 *    Можно использовать как промизом так и коллбэк вариант
 *    примеры:
 *    waitForRender().then(() => functionToBeCalledAfterRender())
 *
 *    await waitForRender()
 *    functionToBeCalledAfterRender()
 *
 *    waitForRender(functionToBeCalledAfterRender)
 *
 * @param {Function} [callback] - Функция обратного вызова, которая будет вызвана после резолва внутреннего промиса
 * @return {Promise<void>}
 */
export function waitForRender(callback) {
    return new Promise((resolve) => {
        Vue.nextTick(() => requestAnimationFrame(() => requestAnimationFrame(resolve)));
    }).then(() => {
        if (typeof callback === 'function') callback();
    });
}
