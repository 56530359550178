<template lang="pug">
.currency-value(:class="[theme, { vertical }]")
    .currency-amount.currency-amount--bonus(v-if="showWithZero || bonusAmount")
        slot(name="bonusAmountValue")
            | {{ $filters.float(bonusAmount) }}
        .bonus-icon.bonus-icon--bets
    .currency-amount.currency-amount--casino(v-if="showWithZero || bonusCasino")
        slot(name="bonusCasinoValue")
            | {{ $filters.float(bonusCasino) }}
        .bonus-icon.bonus-icon--casino
</template>

<script>
export default {
    name: 'BonusAmount',

    inject: ['$filters'],

    props: {
        bonusAmount: {
            type: [Number, String],
            default: 0,
        },
        bonusCasino: {
            type: [Number, String],
            default: 0,
        },
        theme: {
            type: String,
            default: null,
        },
        vertical: Boolean,
        showWithZero: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped lang="stylus">
.currency-value
    display flex
    align-items center

    & > .currency-amount:not(:first-child)
        position relative
        margin-left 15px

        &::before
            content ''
            display block
            position absolute
            width 1px
            height 1em
            background alpha(#000, .1)
            left -8px
            top calc(50% - 0.5em)

    &.light
        & > .currency-amount:not(:first-child)::before
            background alpha(#fff, .1)

    &.vertical
        flex-direction column
        align-items flex-end

        & > .currency-amount:not(:last-child)
            margin-bottom 3px

        & > .currency-amount:not(:first-child)
            margin-left 0

            &::before
                display none

.currency-amount
    color #1b2029
    font-size 15px
    font-weight bold
    // Чтобы background-clip text работал в safari - контейнер должен быть не флексовым
    position relative
    padding-right calc(0.9em + 4px)
    // FRON-1128 возможно перестанут появляться артефакты при зуме, но это не точно
    will-change transform
    transform translateZ(0px)
    letter-spacing 0.1px
    line-height 1.3

    &.currency-amount--bonus
        color $light-green
        background-image radial-gradient(circle at 0 100%, #42b0ff, #69a7ff 98%)
        background-clip text
        -webkit-text-fill-color transparent
        text-shadow 0 0 8px rgba(198, 85, 241, 0.25)

    &.currency-amount--casino
        color #0854c3
        background-image radial-gradient(circle at 0 100%, #ffa3b8, #c04df8 98%)
        background-clip text
        -webkit-text-fill-color transparent
        text-shadow 0 0 8px rgba(198, 85, 241, 0.25)

.bonus-icon
    width 0.9em
    height 0.9em
    background-size contain
    background-repeat no-repeat
    position absolute
    right 0
    top calc(50% - 0.5em)

    &.bonus-icon--bets
        background-image url('~@/assets/images/emoji-soccer.png')
    &.bonus-icon--casino
        background-image url('~@/assets/images/emoji-slots.png')
</style>
