<template lang="pug">
component(
    v-if="!hideSupportPanel"
    :is="supportPanel"
)
</template>

<script>
import {
    ROUTE_META_CUSTOM_SUPPORT_PANEL,
    ROUTE_META_HIDE_SUPPORT_PANEL,
} from '@/constant/constants';

import CommonFixedSupportPanel from '@desktop/layouts/components/SupportPanel/CommonFixedSupportPanel.vue';

export default {
    components: {
        CommonFixedSupportPanel,
    },
    computed: {
        supportPanel() {
            return this.$route.matched
                .map((route) => route.meta[ROUTE_META_CUSTOM_SUPPORT_PANEL])
                .find((customSupportPanel) => !!customSupportPanel) || CommonFixedSupportPanel;
        },
        hideSupportPanel() {
            return this.$route.matched
                .map((route) => route.meta[ROUTE_META_HIDE_SUPPORT_PANEL])
                .some(Boolean);
        },
    },
};
</script>
