<template lang="pug">
.header__line--bottom(
    v-resize="setMaxWidth"
    :class="headerClass"
)
    .level-list(ref="container")
        .level-item(ref="logo")
            .logo-container(
                v-resize="setMaxWidth"
                :class="{ 'invest': isInvestPage }"
            )
                RouterLink.logo-link(:to="{ name: ROUTE_NAME.HOME }")
                    VIcon.logo-invest(
                        v-if="isInvestPage"
                        icon="invest/1-w-invest"
                    )
                    Logo(v-else)

            img.header__locale-flag(
                v-if="showLocaleFlag"
                :src="localeFlag"
            )

        .level-item.fill(ref="leftBottomNav")
            FluidCoverWrapper(
                :gpuScalingInitialHeight="$options.COVER_LINE_INITIAL_HEIGHT"
                :gpuScalingInitialWidth="$options.COVER_LINE_INITIAL_WIDTH"
            )
                template(#cover)
                    NavigationItemCoverLine

                template(#default="{updateFluidCover}")
                    Navigation(
                        v-show="loaded"
                        :maxWidth="maxWidth"
                        @updateActiveLine="updateFluidCover"
                    )

        .level-item(
            ref="rightBottomNav"
            v-resize="setMaxWidth"
        )
            HeaderUserMenu(v-if="$store.getters.isAuthed")
            HeaderAuth(v-else)
</template>

<script>
import { mapGetters } from 'vuex';

import FluidCoverWrapper from '@/components/FluidCoverWrapper.vue';
import VIcon from '@/components/Icons/VIcon.vue';
import Logo from '@/components/Logo/Logo.vue';
import {
    HEADER_INVEST,
    NO_STICKY_HEADER,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
    HEADER_LEADERBOARD,
} from '@/constant/constants';

import ROUTE_NAME from '@desktop/constant/routeName';
import HeaderAuth from '@desktop/layouts/components/Header/HeaderAuth.vue';
import HeaderUserMenu from '@desktop/layouts/components/Header/HeaderUserMenu.vue';
import Navigation from '@desktop/layouts/components/Navigation/Navigation.vue';
import NavigationItemCoverLine from '@desktop/layouts/components/Navigation/NavigationItemCoverLine.vue';

export default {
    name: 'HeaderBottom',

    components: {
        FluidCoverWrapper,
        Logo,
        Navigation,
        NavigationItemCoverLine,
        HeaderAuth,
        HeaderUserMenu,
        VIcon,
    },

    data: () => ({
        ROUTE_NAME,
        loaded: false,
        maxWidth: null,
        isSticky: false,
    }),

    computed: {
        ...mapGetters([
            'user',
            'hideDownloadMobileApp',
            'fullscreen',
            'localeFlag',
            'showLocaleFlag',
        ]),

        text() {
            return {
                ...this.$store.getters.lang.header,
            };
        },

        headerClass() {
            return {
                sticky: this.isSticky,
                'allow-fullscreen': this.fullscreen,
                'invest-theme': this.isInvestPage,
                'no-sticky': this.$route.meta?.[NO_STICKY_HEADER],
                'leaderboard-theme': this.isLeaderboardPage,
            };
        },

        isInvestPage() {
            return this.customHeader === HEADER_INVEST;
        },

        isLeaderboardPage() {
            return this.customHeader === HEADER_LEADERBOARD;
        },

        customHeader() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_HEADER_BOTTOM];
        },
    },

    mounted() {
        this.setMaxWidth();
        new IntersectionObserver(
            this.intersectionObserverCallback,
            {
                // В случае, когда есть скролл по горизонтали обсервер
                // триггерится на горизонтальное пересечение и потом игнорирует вертикальное
                //  добавим побольше брейкпоинтов, чтобы малейшие изменения учитывались
                // eslint-disable-next-line max-len
                threshold: [0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1, 0],
                rootMargin: '-5px 0px 0px 0px',
            },
        ).observe(this.$el);
    },

    methods: {
        intersectionObserverCallback([entry]) {
            this.isSticky = entry.boundingClientRect.top <= 1;
        },

        getMarginBorderPadding(el) {
            const styles = getComputedStyle(el);
            return parseInt(styles.marginLeft || 0, 10)
                + parseInt(styles.marginRight || 0, 10)
                + parseInt(styles.borderLeft || 0, 10)
                + parseInt(styles.borderRight || 0, 10)
                + parseInt(styles.paddingLeft || 0, 10)
                + parseInt(styles.paddingRight || 0, 10);
        },

        getBorderPadding(el) {
            const styles = getComputedStyle(el);
            return parseInt(styles.paddingLeft || 0, 10)
                + parseInt(styles.paddingRight || 0, 10)
                + parseInt(styles.borderLeft || 0, 10)
                + parseInt(styles.borderRight || 0, 10);
        },

        getWidthIncludeMargin(el) {
            const styles = getComputedStyle(el);
            return el.offsetWidth + parseInt(styles.marginLeft, 10) + parseInt(styles.marginRight, 10);
        },

        getWidthExcludePadding(el) {
            const styles = getComputedStyle(el);
            return el.clientWidth - (parseInt(styles.paddingLeft, 10) + parseInt(styles.paddingRight, 10));
        },

        setMaxWidth() {
            const widthNav = this.getWidthExcludePadding(this.$refs.container);
            const indentsLeftNav = this.getMarginBorderPadding(this.$refs.leftBottomNav);
            const widthRightNav = this.getWidthIncludeMargin(this.$refs.rightBottomNav);
            const widthLogo = this.getWidthIncludeMargin(this.$refs.logo);
            const containerPaddings = this.getBorderPadding(this.$refs.container);

            this.maxWidth = widthNav - (indentsLeftNav + widthRightNav + widthLogo + containerPaddings);
            this.loaded = true;
        },
    },

    COVER_LINE_INITIAL_HEIGHT: 47,
    COVER_LINE_INITIAL_WIDTH: 100,
};
</script>

<style lang="stylus" scoped>
@import '~@desktop/styles/mixins.styl'
$maxWidth = 1900px
$margin = 15px

.header__line--bottom
    position sticky
    z-index 100
    top -1px
    height 47px
    padding 0 15px
    // Фикс для сафари
    will-change transform
    transform translateZ(0px)

    &.allow-fullscreen
        z-index 0

    &.sticky:not(.no-sticky)
        .level-list
            &:after
            &:before
                border-radius 0 0 10px 10px
                transform scaleX(1.006)
                box-shadow 0px 2px 5px 3px alpha(#000, .6)

    &.invest-theme
        &.sticky:not(.no-sticky)::before
            opacity .3

        .level-list
            &:after
                opacity 1
            &:before
                opacity 0

            :deep(.login)
                background-color alpha(#94a6cd, .15)

    &.leaderboard-theme
        background-color #01061a

    &.no-sticky
        position absolute
        top $header-top-height
        width "calc(100% - %s * 2)" % $margin
        will-change auto

    .level-list
        max-width $maxWidth
        max-height $header-bottom-height
        height 100%
        margin 0 auto
        padding 0 10px
        display flex
        align-items center
        background-image linear-gradient(92deg, #1e283f, alpha(#141b2e, .6))
        background-color #090f1e
        border-radius 10px
        transition border-radius 0.2s
        //padding-right 0
        // Фикс для сафари
        will-change border-radius, transform
        // Если добавить везде трансформов – на айпаде станет только хуже, так что аккуратней
        //transform translate3d(0, 0, 0)
        position relative

        &:after
        &:before
            position absolute
            content ''
            width 100%
            height 100%
            left 0
            top 0
            z-index -1
            border-radius 10px
            transition opacity .4s ease, border-radius 0.2s, transform .2s

        &:after
            background-image linear-gradient(92deg, alpha(#291f82, 0.9), alpha(#282083, 0.25) 51%, alpha(#3f4bd2, 0.2))
            background-color #0f0840
            opacity 0

        &:before
            background-image linear-gradient(92deg, #1e283f, alpha(#141b2e, .6))
            background-color #090f1e
            opacity 1

        .level-item
            &.fill
                flex-grow 1

            &:not(:last-child)
                margin-right $size-md

.logo-container
    display flex
    align-items center
    justify-content center
    width 68px
    height 24px

    .logo-link
        display flex
        align-items center
        justify-content center
        width 100%
        height 100%

    &.invest
        width fit-content
        height fit-content

.logo-invest
    font-size 21px

.header__locale-flag
    height 15px
    width 15px
    margin-left 3px
    border-radius 50%
    position relative
    top 1px
</style>
