<template lang="pug">
#app
    include ../../common/assets/gradients/svg-gradients
    SvgGradientsDictionary
    UserNotification
    ModalLayout
    NotifyLayout
    TopNotification
    TranslationContainer(v-if="$store.state.bets.currentTranslation")
    RouterView
    PortalTarget(name="portal")
</template>

<script>
import UserNotification from '@/components/Abstract/UserNotification';
import ModalLayout from '@/components/ModalLayout.vue';
import NotifyLayout from '@/components/NotifyLayout.vue';
import SvgGradientsDictionary from '@/components/SvgGradientsDictionary.vue';
import TopNotification from '@/components/TopNotification.vue';

import TranslationContainer from '@desktop/layouts/components/TranslationContainer.vue';

export default {
    components: {
        ModalLayout,
        NotifyLayout,
        SvgGradientsDictionary,
        TopNotification,
        TranslationContainer,
        UserNotification,
    },

    inject: ['userIntention'],

    mounted() {
        this.userIntention.resolve();
        this.$googleTagManager.setUserProperties({
            device_type: 'desktop',
            platform: this.$platform.pwa.any ? 'pwa' : 'web',
            os: this.$platform.os,
        });
    },
};
</script>

<style lang="stylus">
@require '~@/styles/index.styl';
@require '~@desktop/styles/index.styl';
</style>
