<template lang="pug">
.modal-container
    template(v-if="title || additionalTitle || closeable")
        .modal-container__header
            .modal-container__header__row
                .modal-container__header__row__cell
                    .modal-container__header__row__cell__title {{ title }}

                .modal-container__header__row__cell

                .modal-button-login(v-if="$slots.headerCenter")
                    slot(name="headerCenter")

                .modal-container__header__row__account-number
                    .modal-container__header__row__cell__cell {{ additionalTitle }}

                    VButton.modal-container__header__row__cell__overlay(
                        v-if="closeable || showClose"
                        squareSize="24px"
                        icon="times"
                        theme="default"
                        @click="close"
                    )

            .modal-container__header__row(v-if="subtitle")
                .modal-container__header__row__cell
                    .modal-container__header__row__cell__sub-title {{ subtitle }}

    .modal-container__container
        slot(name="body")
            .modal-container__body
                slot

        .modal-container__footer(v-if="$slots.footer")
            slot(name="footer")
</template>

<script>
import VButton from '@/components/VButton.vue';

export default {
    name: 'VModal',
    components: {
        VButton,
    },
    props: {
        isDesktopApp: {
            type: Boolean,
            required: false,
            default: false,
        },
        closeable: {
            type: Boolean,
            required: false,
            default: true,
        },
        showClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        className: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        title: {
            type: String,
            required: false,
        },
        additionalTitle: {
            type: String,
            required: false,
        },
        subtitle: {
            type: String,
            required: false,
        },
    },
    computed: {
        modalClasses() {
            return {
                'is-desktop-app-modal': this.isDesktopApp,
                ...this.className,
            };
        },
    },

    methods: {
        close() {
            if (this.showClose) {
                this.$emit('clickClose');
            }

            if (this.closeable) {
                this.$emit('close');
            }
        },
    },
};
</script>

<style scoped lang="stylus" src="./VModal.styl"></style>
