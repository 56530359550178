<template lang="pug">
img.CybersportTeamIcon(
    v-if="src"
    :src="src"
)
VIcon.CybersportTeamIcon.CybersportTeamIcon--stub-hot(
    v-else-if="hot"
    icon="cybersport-team-stub"
)
.CybersportTeamIcon.CybersportTeamIcon--stub(v-else)
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';

export default {
    components: {
        VIcon,
    },

    props: {
        eSportTeamId: {
            type: [
                Number,
                String,
            ],
        },
        hot: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        src: null,
    }),

    created() {
        try {
            // eslint-disable-next-line
            this.src = `${require(`@/assets/images/esport/${this.eSportTeamId}.png`)}`;
        } catch {} // eslint-disable-line no-empty
    },
};
</script>

<style lang="stylus">
.CybersportTeamIcon
    &--stub
        background-color #b3c9f6
        border-radius 50%
        flex-shrink 0
</style>
