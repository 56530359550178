<template lang="pug">
.CybersportBroadcasts(v-if="show && activeMatch !== null")
    .CybersportBroadcasts__broadcast(v-if="activeMatch")
        MatchTranslation.CybersportBroadcasts__broadcast-transliation(:url="activeMatchBroadcastUrl")

    CybersportHotMatch.CybersportBroadcasts__match(
        v-if="activeMatch !== null"
        :match="activeMatch"
        :showButtonSlide="matchesWithTranslation.length > 1"
        @slidePrev="switchToPrevMatch"
        @slideNext="switchToNextMatch"
    )
</template>

<script>
import { mapGetters } from 'vuex';

import CybersportHotMatch from '@desktop/pages/components/Bets/Cybersport/CybersportHotMatch.vue';
import MatchTranslation from '@desktop/pages/components/Bets/MatchTranslation.vue';

export default {
    components: {
        MatchTranslation,
        CybersportHotMatch,
    },

    props: {
        show: Boolean,
    },

    data: () => ({
        activeMatchId: null,
    }),

    computed: {
        ...mapGetters('xbet', [
            'matchHaveBroadcast',
            'matchBroadcastUrl',
        ]),

        matchesWithTranslation() {
            return this.$store.getters['xbet/live/matchList']
                .filter((m) => this.$store.state.xbet.sports.sportMap[m.sportId]?.isEsport)
                .filter((e) => this.matchHaveBroadcast(e));
        },

        activeMatch() {
            if (!this.activeMatchId && this.matchesWithTranslation.length) {
                return this.matchesWithTranslation[0];
            }

            return this.matchesWithTranslation.find((e) => e.id === this.activeMatchId) ?? null;
        },

        activeMatchIndex() {
            return this.matchesWithTranslation.findIndex((e) => e.id === this.activeMatchId);
        },

        activeMatchBroadcastUrl() {
            return this.activeMatch !== null
                ? this.matchBroadcastUrl(this.activeMatch)
                : null;
        },
    },

    watch: {
        matchesWithTranslation() {
            if (this.activeMatch === null) {
                if (this.matchesWithTranslation.length > 0) {
                    this.activeMatchId = this.matchesWithTranslation[0].id;
                } else {
                    this.activeMatchId = null;
                }
            }
        },
    },

    created() {
        if (this.matchesWithTranslation.length > 0) {
            this.activeMatchId = this.matchesWithTranslation[0].id;
        }
    },

    methods: {
        switchToNextMatch() {
            if (this.activeMatchIndex === this.matchesWithTranslation.length - 1) {
                this.activeMatchId = this.matchesWithTranslation[0].id;
            } else {
                this.activeMatchId = this.matchesWithTranslation[this.activeMatchIndex + 1].id;
            }
        },

        switchToPrevMatch() {
            if (this.activeMatchIndex === 0) {
                this.activeMatchId = this.matchesWithTranslation[this.matchesWithTranslation.length - 1].id;
            } else {
                this.activeMatchId = this.matchesWithTranslation[this.activeMatchIndex - 1].id;
            }
        },
    },
};
</script>

<style scoped lang="stylus">
.CybersportBroadcasts
    display flex
    padding 10px
    background-color $dark-700
    border-radius 12px
    margin-bottom 15px

    &__broadcast
        background-color $dark-400
        border-radius 12px
        overflow hidden
        padding-top 28.1%
        position relative
        max-width 50%
        width 100%

    &__broadcast-transliation
        position absolute
        top 0
        left 0
        height 100%
        width 100%

    &__match
        padding 5px
        margin-left 10px
        flex-grow 1
</style>
