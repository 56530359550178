<template lang="pug">
nav.navigation(
    :style="navigationMaxWidth"
    :class="{ loaded }"
)
    .navigation-navbar(ref="navbar")
        RouterLinkWrapper.navigation-item.df-aic-jcc(
            v-for="item in modifiedNavList"
            :key="item.key"
            v-bind="attributes(item)"
            v-on="events(item)"
        )
            .navigation-item-wrapper.df-aic-jcc(
                :ref="item === activeItem ? 'activeItemRef' : false"
                :hidden="item.hidden"
            )
                .navigation-item-badge(
                    v-if="item.badge"
                    :style="{background: item.badge.background}"
                ) {{item.badge.text}}

                img.navigation-item-image(
                    v-if="item.image"
                    :src="item.image"
                )
                .item-text-block(v-else-if="!(item.items && item.items.length)")
                    .item-text {{ item.name }}
                    .item-text-active {{ item.name }}

                VDropdown.item-text-block(
                    v-else
                    align="center"
                    angle
                    hoverable
                )
                    template(slot="trigger")
                        .item-text {{ item.name }}
                        .item-text-active {{ item.name }}

                    VDropdownItem(
                        v-for="(sub, index) in item.items"
                        :key="index"
                        :target="sub.target"
                        :route=`{
                            name: sub.routeName || item.routeName,
                            params: sub.routeParams,
                            query: sub.routeQuery
                        }`
                        nowrap
                        @click.native="onTailLinkClick(sub, item)"
                    )
                        span.sub-item-text {{ sub.name }}

        .more-item.df-aic-jcc(
            key="more-dropdown"
            :class="{ 'sub-menu-opened': subMenuOpen === 'more-dropdown', 'hidden-item': !moreNumItems }"
            @mouseover="onHoverMore"
            @mouseout="onBlur"
        )
            VDropdown.item-text-block(
                align="center"
                angle
                hoverable
            )
                .more-item-trigger-wrapper(slot="trigger")
                    .more-item-trigger
                        .item-text {{ text.more }}
                        VIcon.more-item-icon(icon="dots")

                VDropdownItem(
                    v-for="sub in tailNavList"
                    :key="sub.key"
                    :target="sub.target"
                    :route="{ name: sub.routeName, params: sub.routeParams }"
                    nowrap
                    @click.native="onTailLinkClick(sub)"
                )
                    img.navigation-item-image(
                        v-if="sub.image"
                        :src="sub.image"
                    )
                    template(v-else)
                        span.sub-item-text(v-text="sub.name")
                        .navigation-item-badge(
                            v-if="sub.badge"
                            :style="{ background: sub.badge.background }"
                            v-text="sub.badge.text"
                        )
</template>
<script>
import { mapGetters } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';
import { VDropdown, VDropdownItem } from '@/components/VDropdown/index';
import { PromiseEnum } from '@/store/promise';
import { waitForRender } from '@/utils/waitForRender';

import RouterLinkWrapper from '@desktop/components/RouterLinkWrapper.vue';
import ROUTE_NAME from '@desktop/constant/routeName';

function isCustomHighlightBehaviour(highlightRoutes, activeRoute) {
    return highlightRoutes.find(
        (item) => item.name === activeRoute.name && Object.entries((item.params)).every(
            ([paramName, paramValue]) => activeRoute.params[paramName] === paramValue,
        ),
    );
}

export default {
    components: {
        RouterLinkWrapper,
        VDropdown,
        VDropdownItem,
        VIcon,
    },

    props: {
        maxWidth: {
            type: Number,
            default: null,
        },
    },

    data: () => ({
        linePosition: -200,
        lineWidth: 20,
        active: null,
        subMenuOpen: null,
        hoverActive: null,
        loaded: false,
        moreNumItems: 0,
    }),

    computed: {
        ...mapGetters([
            'isDesktopApp',
            'casinoAllowed',
            'user',
            'isPreferCasino',
            'isPreferInvest',
            'lang',
        ]),
        ...mapGetters('tv', ['isAllowedTv']),
        ...mapGetters('invest', ['isAllowedInvest']),
        ...mapGetters('permissions', ['isTester']),
        ...mapGetters('navigationMenu', ['orderedTopNavigationMenuItems']),

        text() {
            return this.lang.navigation;
        },

        sortedNavList() {
            return this.orderedTopNavigationMenuItems;
        },

        modifiedNavList() {
            return this.sortedNavList
                .map((item, index) => ({ ...item, hidden: index >= this.sortedNavList.length - this.moreNumItems }));
        },

        activeItem() {
            return this.modifiedNavList.find((item) => this.isActive(item));
        },

        tailNavList() {
            const tail = this.modifiedNavList.filter((item) => item.hidden);
            const flatTail = [];
            tail.forEach((item) => {
                if (item.routeName === ROUTE_NAME.CASES_LIST) {
                    flatTail.push(item);
                    return;
                }
                flatTail.push(...(item.items && item.items.length ? item.items : [item]));
            });
            return flatTail;
        },
        navigationMaxWidth() {
            return `max-width: ${this.maxWidth}px`;
        },
    },

    watch: {
        $route: {
            handler() {
                this.active = this.$route;
                this.updateActiveLine();
            },
            immediate: true,
        },
        maxWidth: {
            immediate: true,
            async handler(val) {
                this.loaded = false;

                if (!val) {
                    return;
                }

                this.sliceNav();

                await this.$store.getters.promiseByName[PromiseEnum.AUTH];

                await waitForRender();

                this.loaded = true;
            },
        },
        moreNumItems: 'updateActiveLine',
    },

    methods: {
        attributes(item) {
            return {
                key: item.key || item.routeName,
                isLink: !item.notLink,
                class: { active: this.isActive(item), 'sub-menu-opened': this.subMenuOpen === item.routeName, 'hidden-item': item.hidden },
                to: {
                    name: item.routeName,
                    params: item.routeParams,
                    hash: item.hash,
                    query: item.routeQuery,
                },
            };
        },

        events(item) {
            return {
                mouseover: this.onHover.bind(this, item),
                mouseout: this.onBlur,
                click: () => {
                    this.navigate.bind(this, item);

                    if (item.scrollTop) {
                        window.scrollTo({
                            left: 0,
                            top: 0,
                            behavior: 'smooth',
                        });
                    }
                },
            };
        },

        isActive({
            routeName,
            routeParams,
            alias,
            items,
            hash = '',
            excludeHighlightRoutes = [],
            includeHighlightRoutes = [],
        }) {
            if (!this.active) {
                return false;
            }

            if (this.active.name === routeName && routeName === ROUTE_NAME.BETS) {
                return this.active.params.service === routeParams.service;
            }

            if (isCustomHighlightBehaviour(excludeHighlightRoutes, this.active)) {
                return false;
            }

            if (isCustomHighlightBehaviour(includeHighlightRoutes, this.active)) {
                return true;
            }

            return (
                this.active.name === routeName
                || alias?.includes(this.active.name)
                || items?.find((item) => this.active.name === item.routeName)
            )
                && this.active.hash === hash;
        },

        navigate() {
            this.subMenuOpen = null;
        },

        onHover({ items, routeName }) {
            this.hoverActive = routeName;
            if (items) {
                this.subMenuOpen = routeName;
            }
        },

        onHoverMore() {
            this.subMenuOpen = 'more-dropdown';
        },

        onBlur() {
            this.hoverActive = null;
            this.subMenuOpen = null;
        },

        updateActiveLine() {
            this.$nextTick(() => {
                const activeItemDomRef = this.$refs.activeItemRef?.[0]?.$el ?? this.$refs.activeItemRef?.[0];
                this.$emit(
                    'updateActiveLine',
                    activeItemDomRef, activeItemDomRef ? this.activeItem.hidden : true,
                );
            });
        },

        getWidthIncludeMargin(el) {
            const styles = getComputedStyle(el);
            return el.offsetWidth + parseInt(styles.marginLeft, 10) + parseInt(styles.marginRight, 10);
        },

        sliceNav() {
            if (!this.$refs.navbar) {
                return;
            }
            const navListElements = [...this.$refs.navbar.children];
            const filteredNavListWidths = navListElements
                .filter((child) => [...child.classList].find((className) => className === 'navigation-item'))
                .map(this.getWidthIncludeMargin);

            const getNavListWidths = () => filteredNavListWidths.reduce((acc, width) => acc + width, 0);

            let moreNumItems = 0;

            if (getNavListWidths() > this.maxWidth) {
                const otherNavListWidth = navListElements
                    .slice(filteredNavListWidths.length)
                    .map(this.getWidthIncludeMargin)
                    .reduce((acc, width) => acc + width, 0);

                while (getNavListWidths() + otherNavListWidth > this.maxWidth) {
                    filteredNavListWidths.pop();
                    moreNumItems += 1;
                }
            }

            this.moreNumItems = moreNumItems;
        },

        onTailLinkClick(sub, item) {
            let { routeName } = sub;
            if (!routeName && item) routeName = item.routeName;
            if (sub.key === 'aviator') routeName = 'aviator-game-promo';
        },
    },
};
</script>
<style lang="stylus" scoped>
.navigation
    max-width min-content

    &:not(.loaded)
        overflow hidden

    .navigation-navbar
        display flex
        width min-content
        align-items stretch
        justify-content flex-start

    .more-item
        color $white
        min-width 65px
        position relative
        font-size 13px
        text-align center
        text-decoration none
        user-select none
        white-space nowrap
        padding 0 $size-sm

        &.hidden-item
            position absolute
            opacity 0
            top -1000px

        &.sub-menu-opened .more-item-trigger-wrapper .more-item-trigger
            background-color alpha(#94a6cd, .15)

        .more-item-trigger-wrapper

            &:hover .more-item-trigger
                background-color alpha(#94a6cd, .25)

        .more-item-trigger
            padding 0 10px
            display flex
            align-items center
            justify-content center
            background-color alpha(#94a6cd, .15)
            border-radius 6px
            transition background-color .15s ease
            margin-top 2px

        .item-text
            line-height 28px

        .sub-item-text
            line-height 15px

        .navigation-item-badge
            margin 0 0 0 8px

        .more-item-icon
            transform rotate(90deg)
            font-size 12px
            width 1em
            height 1em
            margin-left 5px
            color #d4dbeb
            opacity .7

    .navigation-item
        color $white
        line-height 47px
        min-width 65px
        padding 0 $size-sm
        position relative
        font-size 13px
        text-align center
        text-decoration none
        user-select none
        white-space nowrap

        .navigation-item-wrapper
            height 100%

            .navigation-item-image
                min-width 60px

        &.hidden-item
            position absolute
            opacity 0
            top -1000px

        &:not(:last-child)
            margin-right $size-xxs

        .item-text-block
            cursor pointer
            position relative

            .item-text
                padding 0 2px 0

        .item-text-active
            display none
            position absolute
            top 0
            right 0
            bottom 0
            left 0

        &:hover .item-text
        &.active .item-text
            opacity 0

        &:hover .item-text-active
        &.active .item-text-active
            display block
            font-weight 600

        .sub-item-text
            line-height 14px

    .navigation-line
        background-image $gradient-blue
        border-radius 4px 4px 0 0
        bottom 0
        height 4px
        left 0
        opacity 0
        position absolute
        transition opacity .5s
        width 100%

        &.initial
            opacity 1
            transition transform .5s, opacity .5s, width .5s

    .navigation-item-menu
        background-color $white
        border-radius 4px
        display flex
        flex-direction column
        left 0
        padding .5rem 0
        position absolute
        top calc(100% - 5px)
        transform translateY(-50%) scaleY(0)
        transition transform .2s ease-in-out
        z-index 110

        &.opened
            transform translateY(0) scaleY(1)
            visibility initial

    .navigation-menu-trigger
        &.active::after
            border-width 1.45px

    .navigation-sub
        background-color $white
        color $text-black
        line-height 2.5
        padding 0 1rem
        text-transform none
        transition background-color $transition-duration

        &:hover
            background-color $lightgray

    .navigation-item-badge
        border-radius 8px
        margin-right 8px
        height 16px
        width 32px
        display flex
        justify-content center
        align-items center
        font-size 8px
        font-weight 800
        line-height 1
        color #ffffff

    .navigation-item-image
        height 15px
</style>
