<template lang="pug">
#app-overlay-wrapper
    slot
</template>

<script>
export default {

};
</script>

<style lang="stylus" scoped>
    #app-overlay-wrapper
        position: absolute
        top 0
        left 0
        height 0
        width 0
        z-index 10000
</style>
