import { conformToMask } from 'text-mask-core';

import { countries, countriesSorted } from '@/constant/countries';

export const getPhoneCountry = (phone, supposedCountryCode) => {
    if (supposedCountryCode) {
        const country = countries.find((c) => c.code === supposedCountryCode);
        if (phone.includes(country?.prefix)) return country;
    }
    return countriesSorted.find((c) => phone.includes(c.prefix)) || countries[0];
};

export const formatPhone = (phone) => {
    const { prefix, example } = getPhoneCountry(phone);

    const fullExample = `${prefix} ${example}`;
    const mask = fullExample.split('').map((el) => (el.match(/\d/) ? /\d/ : el));

    let { conformedValue } = conformToMask(phone, mask, { guide: false });

    if (phone[0] === '8' && conformedValue[0] === '+') {
        conformedValue = conformedValue.replace('+', '');
    }

    return conformedValue;
};
