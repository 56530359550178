import aviatorGameLogo from '@/assets/images/aviator-game-logo.svg';
import {
    CASES_THEME_LIST,
    PromotedGameIdEnum,
    SERVICE_LIVE,
    SERVICE_PREMATCH,
} from '@/constant/constants';
import {
    convertArrayToNavigationItemsOrderMap,
    getMainNavigationMenuOrderFromLocalStorage,
    getTopNavigationItemsSortFunction,
    NAVIGATION_ITEM_HARDCODED_SORTING_KEY,
    NAVIGATION_ITEM_HARDCODED_SORTING_VALUE_HOME,
    NAVIGATION_ITEM_SORTING_KEY,
    NAVIGATION_ITEMS_SORTING_VALUES,
} from '@/utils/navigation';
import { caseThemeValidatedByLang } from '@/utils/validators';

import ROUTE_NAME from '@desktop/constant/routeName';

const badgeNewObj = {
    text: 'NEW',
    background: 'linear-gradient(-260deg, #7F66D2 0%, #5484F2 100%)',
};

const sortNavListForPreferCasino = [
    'home',
    'casino',
    'casino-live',
    'bets-live',
    'bets-prematch',
    'games',
    'tvbet',
    'invest',
    'virtual-sport',
    'poker',
    'cases',
    'aviator',
    'tv',
    'stats',
    'twain-sport',
];

const sortNavListForPreferInvest = [
    'home',
    'invest',
];

const sortNavList = (preferList, navList) => navList
    .sort((a, b) => {
        const [indexA, indexB] = [a, b].map(({ key }) => preferList.indexOf(key));
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    });

export default {
    namespaced: true,
    getters: {
        topNavigationItems: (state, getters, rootState, rootGetters) => {
            const {
                isReducedFunctionalityBetsOnly: isReducedBets,
                isReducedFunctionalityInvest: isReducedInvest,
                isReducedFunctionalityPoker: isReducedPoker,
            } = rootGetters;

            const isAllowedInvest = rootGetters['invest/isAllowedInvest'];

            const isReducedInvestOrPoker = isReducedInvest || isReducedPoker;
            const isReduced = isReducedBets || isReducedInvest || isReducedPoker;

            const items = [
                {
                    routeName: ROUTE_NAME.HOME,
                    key: 'home',
                    name: rootGetters.lang.navigation.home,
                    hidden: isReducedInvestOrPoker,
                    analyticsName: 'home',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.HOME,
                    [NAVIGATION_ITEM_HARDCODED_SORTING_KEY]: NAVIGATION_ITEM_HARDCODED_SORTING_VALUE_HOME,
                },
                {
                    routeName: ROUTE_NAME.BETS,
                    routeParams: {
                        service: SERVICE_LIVE,
                    },
                    key: 'bets-live',
                    name: rootGetters.lang.navigation.live,
                    hidden: isReducedInvestOrPoker,
                    analyticsName: 'live',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LIVE,
                },
                {
                    routeName: ROUTE_NAME.BETS,
                    routeParams: {
                        service: SERVICE_PREMATCH,
                    },
                    key: 'bets-prematch',
                    name: rootGetters.lang.navigation.prematch,
                    alias: [
                        ROUTE_NAME.BETS_CUSTOM,
                        ROUTE_NAME.BETS_OUTRIGHTS,
                    ],
                    hidden: isReducedInvestOrPoker,
                    analyticsName: 'line',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LINE,
                },
                {
                    routeName: ROUTE_NAME.CASINO,
                    key: 'casino',
                    alias: [
                        ROUTE_NAME.CASINO_WIDGET,
                        ROUTE_NAME.CASINO_OWNER,
                        ROUTE_NAME.CASINO_LIST,
                        ROUTE_NAME.CASINO_FAVORITES,
                        ROUTE_NAME.CASINO_GAME,
                    ],
                    excludeHighlightRoutes: [
                        { name: ROUTE_NAME.CASINO_GAME, params: { id: PromotedGameIdEnum.AVIATOR } },
                        { name: ROUTE_NAME.CASINO_GAME, params: { id: PromotedGameIdEnum.SPRIBE_AVIATOR } },
                        { name: ROUTE_NAME.CASINO_GAME, params: { id: PromotedGameIdEnum.SCOUT_FANTASY } },
                    ],
                    name: rootGetters.lang.navigation['casino-list'],
                    hidden: isReduced,
                    analyticsName: 'casino',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.CASINO,
                },
                {
                    routeName: ROUTE_NAME.CASINO_LIVE,
                    key: 'casino-live',
                    name: rootGetters.lang.navigation['casino-live'],
                    hidden: isReduced,
                    analyticsName: 'liveGames',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LIVE_GAMES,
                },
                {
                    routeName: ROUTE_NAME.BETS_CYBERSPORT_REDIRECT,
                    key: 'bets-cybersport',
                    name: rootGetters.langCommon.bets.cybersport,
                    alias: [
                        ROUTE_NAME.BETS_CYBERSPORT,
                    ],
                    hidden: isReducedInvestOrPoker,
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.CYBERSPORT,
                },
                {
                    routeName: ROUTE_NAME.CASINO_GAME_FANTASY_SPORT,
                    key: 'fantasy-sport',
                    name: 'Fantasy Sport',
                    analyticsName: 'fantasySport',
                    includeHighlightRoutes: [
                        { name: ROUTE_NAME.CASINO_GAME, params: { id: PromotedGameIdEnum.SCOUT_FANTASY } },
                    ],
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.FANTASY_SPORT,
                },
                {
                    routeName: ROUTE_NAME.INVEST,
                    key: 'invest',
                    name: '1win invest',
                    hidden: !isAllowedInvest,
                    analyticsName: 'invest',
                    scrollTop: true,
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.INVEST,
                },
                {
                    routeName: ROUTE_NAME.INVEST,
                    key: 'invest-how-it-works',
                    name: 'Как это работает',
                    hash: '#how-it-works',
                    hidden: !isAllowedInvest || !isReducedInvest,
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.INVEST,
                },
                {
                    routeName: ROUTE_NAME.INVEST,
                    key: 'invest-for-what',
                    name: 'Куда идут инвестиции',
                    hash: '#for-what',
                    hidden: !isAllowedInvest || !isReducedInvest,
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.INVEST,
                },
                {
                    routeName: ROUTE_NAME.INVEST,
                    key: 'invest-about-company',
                    name: 'О компании',
                    hash: '#about',
                    hidden: !isAllowedInvest || !isReducedInvest,
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.INVEST,
                },
                {
                    routeName: ROUTE_NAME.INVEST,
                    key: 'invest-faq',
                    name: 'FAQ',
                    hash: '#faq',
                    hidden: !isAllowedInvest || !isReducedInvest,
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.INVEST,
                },
                {
                    routeName: ROUTE_NAME.GAMES,
                    key: 'games',
                    name: rootGetters.lang.games.title,
                    hidden: isReduced,
                    analyticsName: 'games',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.GAMES,
                },
                {
                    routeName: ROUTE_NAME.BETGAMES_GAME,
                    key: 'betgames',
                    name: 'Betgames',
                    hidden: isReduced,
                    analyticsName: 'betgames',
                    badge: badgeNewObj,
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.BETGAMES,
                },
                {
                    routeName: ROUTE_NAME.TVBET_GAME,
                    key: 'tvbet',
                    name: 'TVBET',
                    hidden: isReduced,
                    analyticsName: 'tvBet',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.TVBET,
                },
                {
                    routeName: ROUTE_NAME.VIRTUAL_SPORT,
                    key: 'virtual-sport',
                    name: rootGetters.lang['virtual-games'].title,
                    alias: [
                        'virtual-sport-game',
                        'virtual-sport-game-betradar',
                    ],
                    hidden: isReducedInvestOrPoker,
                    analyticsName: 'vsport',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.VSPORT,
                },
                {
                    routeName: ROUTE_NAME.POKER,
                    key: 'poker',
                    name: rootGetters.lang.navigation.poker,
                    alias: [
                        ROUTE_NAME.POKER_GAME,
                    ],
                    hidden: isReducedBets || isReducedInvest,
                    analyticsName: 'poker',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.POKER,
                },
                {
                    routeName: ROUTE_NAME.CASINO_GAME_AVIATOR,
                    key: 'aviator',
                    name: 'Aviator',
                    image: aviatorGameLogo,
                    hidden: isReduced,
                    analyticsName: 'aviator',
                    includeHighlightRoutes: [
                        { name: ROUTE_NAME.CASINO_GAME, params: { id: 'aviator' } },
                        { name: ROUTE_NAME.CASINO_GAME, params: { id: 'spribe_aviator' } },
                    ],
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.AVIATOR,
                },
                {
                    routeName: ROUTE_NAME.LEADERBOARD,
                    key: 'leaderboard',
                    name: rootGetters.lang.navigation.leaderboard,
                    hidden: isReduced || !rootGetters['permissions/isTester'],
                    analyticsName: 'leaderboards',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LEADERBOARDS,
                },
                {
                    routeName: ROUTE_NAME.TV,
                    key: 'tv',
                    name: '1win Tv',
                    alias: [
                        ROUTE_NAME.TV_CINEMA,
                        'tv-new',
                        'tv-soon',
                    ],
                    hidden: !rootGetters['tv/isAllowedTv'] || isReducedInvestOrPoker,
                    analyticsName: 'tv',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.TV,
                },
                {
                    routeName: ROUTE_NAME.STATS,
                    key: 'stats',
                    notLink: true,
                    name: rootGetters.lang.navigation.statistics,
                    items: (
                        [rootGetters.lang.navigation.statistics, rootGetters.lang.navigation.results]
                            .map((name, key) => ({
                                key,
                                name,
                                routeParams: {},
                                routeName: ['stats', 'results'][key],
                                routeQuery: {},
                                ...name === rootGetters.lang.navigation.statistics ? {
                                    target: '_blank',
                                } : {},
                            }))
                    ),
                    hidden: isReducedInvestOrPoker,
                    analyticsName: 'statistics',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.STATISTICS,
                },
                {
                    routeName: ROUTE_NAME.CASES_LIST,
                    key: 'cases',
                    alias: ['case'],
                    name: rootGetters.lang.cases.title_menu,
                    items: (
                        Object
                            .entries(rootGetters.lang.cases2.variants ?? {})
                            .map(([key, name]) => ({
                                key,
                                name,
                                routeParams: {},
                                routeQuery: { theme: CASES_THEME_LIST[key] },
                            }))
                            .filter(({ routeQuery: { theme } }) => caseThemeValidatedByLang(
                                theme,
                                rootGetters.currentLang,
                            ))
                    ),
                    onClick: ({ key }) => rootGetters.$store.commit('cases/changeTheme', CASES_THEME_LIST[key]),
                    hidden: isReduced,
                    analyticsName: 'cases',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.CASES,
                },
                {
                    routeName: ROUTE_NAME.GAMES_ONE_WIN,
                    key: 'games-one-win',
                    name: rootGetters.lang.navigation['casino-games-one-win'],
                    hidden: isReduced,
                    analyticsName: 'games-one-win',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.GAMES_ONE_WIN,
                },
                {
                    routeName: ROUTE_NAME.TWAINSPORT_GAME,
                    key: 'twain-sport',
                    name: 'Twain sport',
                    hidden: isReduced,
                    badge: badgeNewObj,
                    analyticsName: 'twain-sport',
                    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.TWAINSPORT,
                },
            ]
                .filter((item) => {
                    if (item.hidden) {
                        return false;
                    }

                    const casino = ['casino-list', 'casino-live'];
                    if (!casino.includes(item.routeName)) return true;

                    return rootGetters.casinoAllowed;
                });

            if (rootGetters.isPreferCasino) {
                sortNavList(sortNavListForPreferCasino, items);
            }

            if (rootGetters.isPreferInvest) {
                sortNavList(sortNavListForPreferInvest, items);
            }

            return items;
        },
        topNavigationMenuItemsOrderArray: (_, __, ___, { user }) => (
            user?.hamburger_menu
            || getMainNavigationMenuOrderFromLocalStorage()
        ),
        topNavigationMenuItemsOrderMap: (_, getters) => (
            convertArrayToNavigationItemsOrderMap(getters.topNavigationMenuItemsOrderArray)
        ),
        orderedTopNavigationMenuItems: (_, getters) => {
            let resultItems = getters.topNavigationItems;

            const { topNavigationMenuItemsOrderMap } = getters;
            if (topNavigationMenuItemsOrderMap) {
                resultItems = resultItems
                    .slice()
                    .sort(getTopNavigationItemsSortFunction(topNavigationMenuItemsOrderMap));
            }
            return resultItems;
        },
    },
};
