<template lang="pug">
.v-image
    .v-image-sizing(v-if="imageRatio" :class="{ loading }" :style="sizingStyle")
    img(v-bind="vBind" @load="loaded")
</template>

<script>
export default {
    name: 'VImage',

    props: {
        image: {
            type: [Object, String],
            required: true,
        },

        ratio: {
            type: Number,
            default: null,
        },
    },

    data: () => ({
        loading: true,
    }),

    computed: {
        vBind() {
            const { srcSet, src } = this.image;

            return {
                ...srcSet ? { srcset: srcSet } : {},
                src: src || this.image,
                class: {
                    'v-image-ratio': this.imageRatio,
                },
            };
        },

        sizingStyle() {
            return {
                paddingBottom: `calc(100% * ${this.imageRatio})`,
            };
        },

        imageRatio() {
            const { width = 1, height = 0 } = this.image;
            return this.ratio || (height / width);
        },
    },

    created() {
        window.addEventListener('orientationchange', this.$forceUpdate, true);
    },

    destroyed() {
        window.removeEventListener('orientationchange', this.$forceUpdate, true);
    },

    methods: {
        loaded() {
            this.loading = false;
        },
    },
};
</script>

<style scoped lang="stylus">
@keyframes loading
    10%
        background-image repeating-linear-gradient(
            -45deg, #000, #000 1px, #141b2e 1px, #141b2e 11px
        )
    20%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 1px, #000 1px, #000 2px, #141b2e 2px, #141b2e 11px
        )
    30%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 2px, #000 2px, #000 3px, #141b2e 3px, #141b2e 11px
        )
    40%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 3px, #000 3px, #000 4px, #141b2e 4px, #141b2e 11px
        )
    50%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 4px, #000 4px, #000 5px, #141b2e 5px, #141b2e 11px
        )
    60%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 5px, #000 5px, #000 6px, #141b2e 6px, #141b2e 11px
        )
    70%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 6px, #000 6px, #000 7px, #141b2e 7px, #141b2e 11px
        )
    80%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 7px, #000 7px, #000 8px, #141b2e 8px, #141b2e 11px
        )
    90%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 8px, #000 8px, #000 9px, #141b2e 9px, #141b2e 11px
        )
    100%
        background-image repeating-linear-gradient(
            -45deg, #141b2e, #141b2e 9px, #000 9px, #000 10px, #141b2e 10px, #141b2e 11px
        )

.v-image
    width 100%
    position relative
    flex none

    img.v-image-ratio
        position absolute
        top 0
        left 0

    .v-image-sizing
        content ''
        display block
        width 100%

        &.loading
            opacity .1
            border-radius 10px
            animation loading .8s infinite linear
            background-image: repeating-linear-gradient(-45deg, #141b2e, #141b2e 10px, #000 10px, #000 11px)
</style>
