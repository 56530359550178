import { render, staticRenderFns } from "./LoadingDots.vue?vue&type=template&id=3a0570d1&scoped=true&lang=pug&"
import script from "./LoadingDots.vue?vue&type=script&lang=js&"
export * from "./LoadingDots.vue?vue&type=script&lang=js&"
import style0 from "./LoadingDots.vue?vue&type=style&index=0&id=3a0570d1&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a0570d1",
  null
  
)

export default component.exports