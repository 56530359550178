<template lang="pug">
component.button(
    :is="tag"
    :class="classObject"
    :style="style"
    :disabled="disabled"
    :type="notPrevent || submit ? 'submit' : 'button'"
    :to="to"
    @click="onclick($event)"
)
    LoadingDots(v-if="loading")
    template(v-else)
        span.icon-wrap(v-if="icon")
            VIcon(
                inline
                :icon="icon"
            )
        slot
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';
import LoadingDots from '@/components/LoadingDots.vue';

export const BUTTON_THEMES = [
    'default',
    'green',
    'secondary',
    'grey',
    'blue',
    'text-grey',
    'text-primary',
    'grey-light',
    'white',
    'yellow',
    'purple',
];

export default {
    name: 'VButton',

    components: {
        LoadingDots,
        VIcon,
    },

    props: {
        active: Boolean,
        className: {
            type: String,
            default: null,
        },
        disabled: Boolean,
        rounded: Boolean,
        size: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        wide: Boolean,
        notPrevent: Boolean,
        submit: Boolean,
        icon: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'button',
        },
        to: {
            type: [Object, String],
            default: null,
        },
        squareSize: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'default',
            validator: (theme) => BUTTON_THEMES.includes(theme),
        },
        loading: Boolean,
    },

    computed: {
        classObject() {
            return {
                active: this.active,
                [this.className]: this.className,
                disabled: this.disabled,
                fullwidth: this.wide,
                rounded: this.rounded,
                [`size-${this.size}`]: this.size,
                [this.type]: this.type,
                square: this.squareSize,
                [`theme-${this.theme}`]: this.theme,
            };
        },

        style() {
            return {
                '--square-size': this.squareSize,
            };
        },
    },

    methods: {
        onclick(e) {
            if (!this.notPrevent) {
                e.preventDefault();
            }
            this.$emit('click');
        },
    },
};
</script>

<style lang="stylus" scoped>
.button
    will-change transform

    &.square
        width var(--square-size)
        height var(--square-size)
        min-width 0
        min-height 0
        padding 0
        border-radius 8px

        .icon-wrap
            margin 0
            background none

    &.theme-blue
        background linear-gradient(90.77deg, #0095FF 0.96%, #0855C4 99.87%)
        box-shadow $blue-shadow
        text-shadow 0px 4px 8px rgba(0, 0, 0, 0.1)
        border-radius 10px

    &.theme-green
        background $green-gradient
        box-shadow $green-shadow
        text-shadow 0 3px 6px rgba(0, 0, 0, 0.2)

    &.theme-secondary
        background rgba(151, 174, 225, 0.2)
        text-shadow 0 3px 5px rgba(9, 15, 30, 0.2)
        position relative

    &.size-lg
        font-size 1.15rem
        line-height 1.43
        min-height 35px

    &.theme-grey
        background #EDF0F7
        color #c4c4c4

    buttonText() {
        background none
        border none
        border-radius unset
        height auto
        min-height unset
        min-width unset
        padding 0
    }

    buttonTextIcon() {
        font-size 1.1em
        margin 0 3px 0 0
        background none
    }

    &.theme-text-grey
        buttonText()
        color alpha(#6A7690, 0.65)

        .icon-wrap
            buttonTextIcon()

    &.theme-text-primary
        buttonText()
        color #1077DE

        .icon-wrap
            buttonTextIcon()

    &.theme-white
        border-radius 12px
        font-family $font-family-heading
        font-weight 600
        font-size 14px
        line-height 17px
        letter-spacing 0.5px
        color #7134F7
        text-shadow 0px 4px 12px rgba(0, 0, 0, 0.1)
        background #FFFFFF
        padding 0 23px

    &.theme-yellow
        background radial-gradient(65.27% 103.64% at 54.19% 50.91%, #FFBF1C 0.52%, #FCD701 100%)
        padding 0 12px
        border-radius 12px
        color #554406
        text-shadow 0 4px 10px rgba(0, 0, 0, 0.2)
        font-size 16px
        font-weight 600

    &.theme-purple
        background radial-gradient(83.41% 348% at 50% 50%, #6732EC 0%, #7059FF 100%)
        border-radius 12px
        text-align center

    &.theme-grey-light
        background-image linear-gradient(0deg, transparent 0%, transparent 100%)
        background-color #f3f6fb
        color #5c72a3

.icon-wrap
    width 20px
    height 20px
    display inline-flex
    align-items center
    justify-content center
    border-radius 50%
    background-image linear-gradient(210deg, rgba(#fff, 0) 25%, rgba(#fff, 0.06) 48%, #fff 133%)
    margin-right 12px
    margin-left -5px
</style>
