<template lang="pug">
RouterLink(
    v-if="isLink"
    :to="to"
    @mouseover.native="$listeners.mouseover"
    @mouseout.native="$listeners.mouseout"
    @click.native="$listeners.click"
)
    slot
div(
    v-else
    @mouseover="$listeners.mouseover"
    @mouseout="$listeners.mouseout"
)
    slot
</template>

<script>
export default {
    name: 'RouterLinkWrapper',
    props: {
        isLink: {
            type: Boolean,
            default: true,
        },
        to: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
