const timeout = 3000;

const setDifferenceClass = (curr, prev, el) => {
    if (!curr || !prev) return;

    const clearClass = () => el.classList.remove('up', 'down');

    clearClass();
    el.classList.add(curr > prev ? 'up' : 'down');

    clearTimeout(el.differenceClassTimerId);
    el.differenceClassTimerId = setTimeout(clearClass, timeout);
};

export default setDifferenceClass;
