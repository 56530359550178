import { createStore as defaultCreateStore } from '@/store/MainStore';

import betsNavigation from './navigation';
import navigationMenu from './navigationMenu';

/**
 * @returns {import('vuex').Store}
 */
export function createStore() {
    const store = defaultCreateStore();

    store.registerModule('navigationMenu', navigationMenu);
    store.registerModule('betsNavigation', betsNavigation);

    return store;
}
