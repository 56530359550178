<template lang="pug">
VModalDynamic(
    name="call-support"
    :title="text.title"
    :class-name=`{ 'call-support-modal': true }`
    @close="$emit('close')"
)
    .call-support.level
        .level-item.card.box.expanded
            .text
                .title {{ text.phone.title }}
                .description
                    div {{ text.phone.timeWorking }}
                    div
                        a(
                            v-if="supportNumber"
                            :href="`tel:${supportNumber}`"
                        ) {{ isRussianLocale ? russianCallMain : supportNumberVisually }}
                    div
                        a(
                            v-if="additionalSupportNumber"
                            :href="`tel:${additionalSupportNumber}`"
                        ) {{ isRussianLocale ? russianCallAdditional : additionalSupportNumberVisually }}
</template>

<script>
import { mapGetters } from 'vuex';

import { LANG_RU, RUSSIAN_LOCALE_ID } from '@/constant/constants';
import { formatString } from '@/plugins/LanguageTools';
import { formatPhone } from '@/utils/phone';

import config from '@config/app.config';
import VModalDynamic from '@desktop/components/VModalDynamic/VModal.vue';

export default {
    name: 'CallSupportModal',
    components: {
        VModalDynamic,
    },

    computed: {
        ...mapGetters([
            'lang',
            'currentLang',
            'supportNumber',
            'locale',
        ]),

        text() {
            return this.lang['call-support'].modal;
        },

        supportNumberVisually() {
            return formatPhone(this.supportNumber);
        },

        additionalSupportNumber() {
            return !this.locale && this.currentLang === LANG_RU
                ? config.phoneAdditional : this.locale.additionalPhoneSupport;
        },

        additionalSupportNumberVisually() {
            return formatPhone(this.additionalSupportNumber);
        },

        isRussianLocale() {
            return this.locale == null || this.locale.id === RUSSIAN_LOCALE_ID;
        },

        russianCallMain() {
            return formatString(this.text.phone.russianCallMain, this.supportNumberVisually);
        },

        russianCallAdditional() {
            return formatString(this.text.phone.russianCallAdditional, this.additionalSupportNumberVisually);
        },
    },
};
</script>

<style lang="stylus" scoped>
.call-support
    display flex
    flex-direction column

    .level-item
        align-items flex-start
        color white
        text-decoration none
        width 100%

        &:not(:last-child)
            margin-bottom 1em

    .title
        font-size $font-size-xl
        font-weight $font-weight-semi-bold
        margin-bottom $size-sm

    .description
        color $text-secondary

    .image-support
        align-self center
        font-size 48px
</style>
