/**
 * @typedef {Object} OddConfig
 * @property {Odd} odd
 * @property {OddBaseConfig} config
 *
 * @param odd
 * @param outcome
 * @param config
 * @param specialValue
 * @param isSpecial
 * @returns {object}
 */
export default ({
    odd,
    outcome,
    config,
    specialValue,
    isSpecial,
}) => {
    const fixed = odd?.coefficient > 99 ? 0 : 2;
    const coefficient = odd?.coefficient?.toFixed(fixed);
    const special = specialValue;

    return {
        odd,
        config,
        outcome,
        specialValue,
        isSpecial: !!isSpecial,
        key: odd ? odd.id : `${config.groups.join()}${outcome?.outcome}`,
        name: outcome?.translations,
        disabled: !!(!!odd?.disabled || special),
        special,
        value: special || coefficient,
        coefficient,
    };
};
