import { GUARD_VERIFY, GUARD_SHOW_VERIFY } from '@/constant/constants';
import { ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS } from '@/constant/rules';
import { AUTH, checkAuthValidate } from '@/plugins/AuthRules';
import { CALL_SUPPORT, checkCallSupportAllowed } from '@/plugins/CallSupportRules';
import { PromiseEnum } from '@/store/promise';
import { isOneClickModalSettingsValid } from '@/utils/modalSettingsValidators';

import { MODAL_VERIFICATION } from '@desktop/constant/modalName';

const callSupportGuard = (modal, store) => {
    const { meta = {} } = modal;

    if (CALL_SUPPORT in meta) {
        const isValid = checkCallSupportAllowed(meta[CALL_SUPPORT], store);
        if (!isValid) {
            throw new Error('Call support not allowed');
        }
    }
};

const authGuard = async (modal, store) => {
    const { meta = {} } = modal;

    if (AUTH in meta) {
        await Promise.all([
            store.rootGetters.promiseByName[PromiseEnum.AUTH],
            store.rootGetters.promiseByName[PromiseEnum.AUTH_TOKEN],
        ]);

        const isAllowRegister = (
            meta[ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS]
            && isOneClickModalSettingsValid(modal.options.props?.modalSettings)
        );

        const isAuthValid = checkAuthValidate(meta[AUTH], store.rootGetters.isAuthed);

        if (isAuthValid === false && !isAllowRegister) {
            await Promise.reject(new Error('only authorized'));
        }
    }
};

const verifyGuard = async (modal, store, openModal) => {
    const { meta = {} } = modal;

    if (GUARD_VERIFY in meta) {
        await store.dispatch('getVerifyStatus', null, { root: true });

        if (store.rootGetters.blockedByVerification) {
            setTimeout(() => {
                openModal(MODAL_VERIFICATION);
            });

            await Promise.reject(new Error('verify'));
        }
    }
};

const showVerifyGuard = async (modal, store) => {
    const { meta = {} } = modal;

    if (GUARD_SHOW_VERIFY in meta && !store.rootGetters.showVerify) {
        await Promise.reject(new Error('show verify'));
    }
};

export default async function modalGuard(modal, store, openModal) {
    await Promise.all([
        store.rootGetters.promiseByName[PromiseEnum.LANG],
        authGuard(modal, store),
        callSupportGuard(modal, store),
        verifyGuard(modal, store, openModal),
        showVerifyGuard(modal, store),
    ]);
}
