import Vue from 'vue';

import resizeDirective from '@/directives/resize';
import scrollLoad from '@/directives/scrollLoad';
import scrollRememberDirectiveFactory from '@/directives/scrollRemember';
import visible from '@/directives/visible';
import watchDirective from '@/directives/watch';
import {
    useLocale,
    useParseError,
    useModalProvider,
    useNotification,
    useUserIntentionMemo,
    useFilters,
} from '@/hooks';
import { useAmplitudeExperiment } from '@/hooks/useAmplitudeExperiment';
import AppTitlePlugin from '@/plugins/AppTitle';
import existInvest from '@/plugins/ExistInvest';
import GoogleTagManager from '@/plugins/GoogleTagManager';
import NavigationMenuPlugin from '@/plugins/NavigationMenuPlugin';
import pluginOverlay from '@/plugins/overlay';
import AppOverlay from '@/plugins/overlay/AppOverlay.vue';
import { devicePlugin } from '@/plugins/Platform';
import PushApiPlugin from '@/plugins/PushApiPlugin';
import SaveOauthDataPlugin from '@/plugins/SaveOauthDataPlugin';
import CheckVersionService from '@/services/CheckVersionService';

import { MODAL_LOCALE } from '@desktop/constant/modalName';
import modalDescriptions from '@desktop/modals';
import modalGuard from '@desktop/modals/guard';
import { createStore } from '@desktop/store';

import RootLayout from './layouts/RootLayout.vue';
import { createRouter } from './router/router';

import './styles/index.styl';

/**
 * @param {import('vue-router').VueRouter} router
 * @returns {void}
 */
const registerDirectives = ({ router }) => {
    Vue.directive('resize', resizeDirective);
    Vue.directive('scroll-load', scrollLoad);
    Vue.directive('scroll-remember', scrollRememberDirectiveFactory(router));
    Vue.directive('visible', visible);
    Vue.directive('watch', watchDirective);
};

/**
 * @param {import('vue-router').VueRouter} router
 * @param {import('vuex').Store} store
 * @returns {void}
 */
const registerPlugins = ({ router, store }) => {
    Vue.use(AppTitlePlugin({ router, store }));
    Vue.use(devicePlugin(navigator.userAgent));
    Vue.use(existInvest(store));
    Vue.use(GoogleTagManager);
    Vue.use(NavigationMenuPlugin({ store }));
    Vue.use(
        pluginOverlay({
            router,
            store,
            component: AppOverlay,
        }),
    );
    Vue.use(PushApiPlugin(store));
    Vue.use(SaveOauthDataPlugin(store));
};

/**
 * @returns {Promise<{ app: Vue, router: import('vue-router').VueRouter, store: import('vuex').Store }>}
 */
export const createApp = async () => {
    const store = createStore();
    const router = createRouter(store);

    registerDirectives({ router });
    registerPlugins({ router, store });

    CheckVersionService.start(router);

    /**
     * @property {Filters} $filters
     * @property {Modal} $modal
     * @property {Object} $notify
     * @property {Function} parseError
     * @property {Object} supportChat
     * @property {Object} locale
     * @property {Object} userIntention
     */
    const app = new Vue({
        router,
        store,
        provide() {
            const parseError = useParseError(store);
            const $modal = useModalProvider(store, modalDescriptions, modalGuard);
            const $notify = useNotification(parseError);
            const locale = useLocale(store, () => {
                $modal.openOver(MODAL_LOCALE);
            });
            const userIntention = useUserIntentionMemo(store, router);
            const $filters = useFilters(store);
            const amplitudeExperiment = useAmplitudeExperiment(store);

            return {
                $filters,
                $modal,
                $notify,
                parseError,
                locale,
                userIntention,
                amplitudeExperiment,
                store,
            };
        },
        render: (h) => h(RootLayout),
    });

    return {
        app,
        router,
        store,
    };
};
