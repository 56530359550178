<template lang="pug">
.translation
    iframe.translation-iframe(
        v-if="$store.getters.isAuthed"
        ref="iframe"
        :src="urlNormalized"
        allowfullscreen
        mozallowfullscreen
        webkitallowfullscreen
        scrolling="no"
    )
    .translation-no-auth(v-else)
        .translation-no-auth-title {{ text.title }}
        .translation-no-auth-subtitle
            | {{ text.subtitle }}
            span.translation-link(@click="openModalRegister")  {{ text.reg }}
            |  {{ text.or }}
            span.translation-link(@click="openModalLogin")  {{ text.login }}
            |  {{ text.gg }}
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import { appendParentParamToTwitchUrl } from '@/utils/appendParentParamToTwitchUrl';

import { MODAL_REGISTER, MODAL_LOGIN } from '@desktop/constant/modalName';

export default {
    components: {
        Spinner,
    },

    inject: ['$modal'],

    props: {
        url: {
            type: String,
            required: true,
        },
    },

    computed: {
        text() {
            return this.$store.getters.lang.match_translation_info;
        },

        urlNormalized() {
            if (this.url.includes('twitch')) {
                return appendParentParamToTwitchUrl(this.url);
            }
            return this.url;
        },
    },

    methods: {
        openModalRegister() {
            this.$modal.open(MODAL_REGISTER);
        },
        openModalLogin() {
            this.$modal.open(MODAL_LOGIN);
        },
    },
};
</script>

<style scoped lang="stylus">
    .translation
        position relative
        width 100%
        height 0
        padding-bottom calc((9 / 16 * 100%) + 3px)
        overflow hidden
        border-radius 10px
        z-index 2

        .translation-no-auth
        .translation-iframe
        .translation-loader
            position absolute
            top 0
            bottom 0
            left 0
            right 0
            width 100%
            height 100%
            border none
            z-index 1

        .translation-loader
            display flex
            align-items center
            justify-content center

        .translation-link
            cursor pointer
            color $blue

        .translation-no-auth
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            text-align center

            &-title
                font-size: 16px
                font-weight: 600
                color white
                margin-bottom: 10px

            &-subtitle
                font-size: 13px
                color #dde2eb
                width: 200px
</style>
