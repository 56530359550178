<template lang="pug">
Forbidden(v-if="siteShowed && blockWholeSite")
#main-container(
    v-else-if="siteShowed"
    :class="[allowFullScreenModeClass, { 'tv-full-screen': fullScreen }]"
)
    ShowingDividends

    HeaderRules(v-if="isRulesPage")

    template(v-else)
        HeaderTop
        HeaderBottom

    PushNotification

    main.content-wrapper(:class="allowFullScreenModeClass")
        transition(:name="transition" mode="out-in")
            <!--router-view.container(:key="$route.name")-->
            RouterView

    SupportPanel
    ToolbarPanel
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ShowingDividends from '@/components/Abstract/ShowingDividends';
import Forbidden from '@/components/Forbidden.vue';
import VIcon from '@/components/Icons/VIcon.vue';
import VButton from '@/components/VButton.vue';
import {
    ROUTE_META_CUSTOM_HEADER,
    HEADER_RULES,
} from '@/constant/constants';
import { AUTH_REQUIRED } from '@/plugins/AuthRules';
import { closeLoader } from '@/utils/globalLoader';

import PushNotification from '@desktop/components/Notifications/PushNotification.vue';
import { MODAL_CALL_SUPPORT, MODAL_REGISTER } from '@desktop/constant/modalName';
import ROUTE_NAME from '@desktop/constant/routeName';
import HeaderBottom from '@desktop/layouts/components/Header/HeaderBottom.vue';
import HeaderRules from '@desktop/layouts/components/Header/HeaderRules.vue';
import HeaderTop from '@desktop/layouts/components/Header/HeaderTop.vue';
import SupportPanel from '@desktop/layouts/components/SupportPanel/SupportPanel.vue';
import ToolbarPanel from '@desktop/layouts/components/ToolbarPanel/ToolbarPanel.vue';
import CallSupportModal from '@desktop/modals/CallSupportModal.vue';
import TelegramProxyModal from '@desktop/modals/TelegramProxyModal.vue';

export default {
    components: {
        PushNotification,
        HeaderBottom,
        HeaderTop,
        HeaderRules,
        CallSupportModal,
        Forbidden,
        TelegramProxyModal,
        VButton,
        SupportPanel,
        ShowingDividends,
        ToolbarPanel,
        VIcon,
    },

    inject: [
        '$modal',
        '$notify',
    ],

    data: () => ({
        transition: 'fade',
    }),

    computed: {
        ...mapState([
            'telegramHash',
        ]),
        ...mapGetters([
            'langCommon',
            'blockWholeSite',
            'casinoAllowed',
            'fullscreen',
            'isAuthed',
            'lang',
            'siteShowed',
            'user',
        ]),
        ...mapState('tv', [
            'fullScreen',
        ]),
        allowFullScreenModeClass() { // needed for problems in safari browser
            return {
                'allow-fullscreen': this.fullscreen,
            };
        },

        isRulesPage() {
            return this.customHeader === HEADER_RULES;
        },

        customHeader() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_HEADER];
        },
    },

    watch: {
        $route(to, from) {
            this.transition = from.name === to.name ? '' : 'fade';
        },
        user: {
            deep: true,
            async handler() {
                if (this.isAuthed && !this.user.timezone) {
                    await this.$store.dispatch('updateTimezone');
                }
            },
        },
        async isAuthed() {
            // If store contains telegram hash, than try to authorize user in bot
            if (this.telegramHash) {
                try {
                    await this.$store.dispatch('sendTelegramHash', {
                        hash: this.telegramHash,
                        token: this.user.token,
                    });
                } catch (err) {
                    await this.$notify.error(this.lang.ERROR_1001);
                }
            }
        },
    },

    created() {
        if (this.$route.meta[AUTH_REQUIRED] && !this.isAuthed) {
            this.$modal.open(MODAL_REGISTER, {
                onClose: () => {
                    this.$nextTick(() => {
                        if (!this.isAuthed) {
                            this.$router.push({ name: ROUTE_NAME.HOME });
                        }
                    });
                },
            });
        }
    },

    mounted() {
        document.getElementById('call-support_2')?.remove();
        closeLoader();
    },

    methods: {
        openModalCallSupport() {
            this.$modal.open(MODAL_CALL_SUPPORT);
        },
    },
};
</script>

<style lang="stylus" scoped>
@import '~@desktop/styles/mixins.styl'

#main-container
    position relative
    width 100%

    &.allow-fullscreen
        overflow hidden
        position fixed

    &.tv-full-screen
        z-index 0 // Fix https://fbet-gitlab.ex2b.co/frontend/1win-main/-/issues/2315

.bonus-button
    bottom 170px

.content-wrapper
    container(0px)
    position relative
    z-index 99

    &.allow-fullscreen
        overflow initial

.modal-wrapper
    align-items center
    backdrop-filter blur(1px)
    background-color rgba(9, 15, 30, 0.7)
    bottom 0
    display flex
    justify-content center
    left 0
    overflow hidden
    padding 15px
    position absolute
    right 0
    top 0
    z-index 999
    align-self center
    justify-self center

.call-support-container
    bottom 15px
    position fixed
    right 15px
    z-index 999

    .call-support-call
        background-image linear-gradient(112deg, #108de7 0%, #2972d6 100%)

    .call-support-text
        background-image linear-gradient(112deg, #2972d6 0%, #2662cc 100%)

    .call-support-chat
        background-image linear-gradient(112deg, #2662cc 0%, #2662cc 100%)
</style>
