import { AppStorage, TIMESTAMP_KEY } from '@/plugins/AppStorage';

const CHECK_INTERVAL = 1000 * 60 * 60 * 24 * 14; // 14 days

let timer;

/**
 * @param timestamp {number}
 * @return {number}
 */
const timeAfter = (timestamp) => Date.now() - CHECK_INTERVAL + timestamp;

/**
 * @param timestamp {number}
 */
const updateTimestamp = (timestamp) => {
    AppStorage.set(TIMESTAMP_KEY, timestamp);
};

/**
 * @param cb {function}
 * @param [afterTime] {number}
 */
export const open = (cb, afterTime) => {
    let time = afterTime;
    clearTimeout(timer);

    if (afterTime === undefined) {
        const lastTimestamp = +AppStorage.getOr(TIMESTAMP_KEY, timeAfter(1000 * 60 * 10)); // 10 minutes

        if (lastTimestamp + CHECK_INTERVAL <= Date.now()) {
            time = 0;
        } else {
            time = lastTimestamp + CHECK_INTERVAL - Date.now();
        }
    }

    updateTimestamp(timeAfter(time));

    timer = setTimeout(() => {
        cb();
        updateTimestamp(Date.now());
    }, time);
};
