const APP_ID = 'app-overlay';
const AppOverlay = ({
    router,
    store,
    component,
    components = [],
} = {}) => ({
    install(Vue) {
        const overlayElement = document.createElement('div');

        overlayElement.id = APP_ID;

        document.body.appendChild(overlayElement);

        new Vue({
            ...router ? { router } : {},
            ...store ? { store } : {},
            render: (h) => h(component, components.map((it) => h(it))),
        }).$mount(`#${APP_ID}`);
    },
});

export default AppOverlay;
