<template lang="pug">
article.mutlicurrency
    section.mutlicurrency-container
        section.currency
            .currency-container
                .currency-icon {{ $filters.currency(user.currency) }}
                hr.currency-separator
                span.currency-amount {{ $filters.float(balance) }}

            button.deposit-button(@click="openModalDeposit(user.currency)")
                VIcon.deposit-button-icon(icon="circle-plus")

    section.mutlicurrency-container(v-if="showBalance && user.bonus_amount || activeBalanceSecondaryList.length")
        h1.mutlicurrency-title {{ text.walletsOther }}
        ul.currency-list
            li.currency-item(
                v-if="showBalance && hasBonus"
                :key="BONUS"
            )
                section.currency
                    VIconCurrency.currency-icon.currency-icon-bonus(:currency="BONUS")
                    hr.currency-separator
                    BonusAmount.bonus-currency(
                        :bonusAmount="bonusAmount"
                        :bonusCasino="bonusCasino"
                    )
                    span.currency-subtitle {{ text.bonus }}

            li.currency-item(
                v-for="{ currency, amount } in activeBalanceSecondaryList"
                :key="currency"
            )
                section.currency
                    .currency-container
                        .currency-icon {{ $filters.currency(currency) }}
                        hr.currency-separator
                        span.currency-amount {{ $filters.float(amount) }}
                    CurrencyDropdown.currency-dropdown(:currency="currency")

    section.mutlicurrency-container
        VButton.open-account-management-button(
            type="blue"
            @click="openAccountManagement"
        ) {{ text.walletManagement }}
</template>

<script>
import { mapGetters } from 'vuex';

import BonusAmount from '@/components/BonusAmount.vue';
import VIcon from '@/components/Icons/VIcon.vue';
import VButton from '@/components/VButton.vue';
import { BONUS } from '@/constant/currency';

import CurrencyDropdown from '@desktop/components/CurrencyItem/CurrencyDropdown.vue';
import VIconCurrency from '@desktop/components/VIconCurrency.vue';
import { MODAL_DEPOSIT, MODAL_ACCOUNT_MANAGEMENT } from '@desktop/constant/modalName';

export default {
    name: 'HeaderMulticurrency',

    components: {
        BonusAmount,
        VButton,
        CurrencyDropdown,
        VIconCurrency,
        VIcon,
    },

    inject: [
        '$filters',
        '$modal',
    ],

    data: () => ({
        BONUS,
    }),

    computed: {
        ...mapGetters([
            'balance',
            'activeBalanceSecondaryList',
            'lang',
            'showBalance',
            'user',
            'hasBonus',
            'bonusAmount',
            'bonusCasino',
        ]),

        text() {
            return this.lang.HeaderMulticurrency;
        },
    },

    methods: {
        openAccountManagement() {
            this.$modal.open(MODAL_ACCOUNT_MANAGEMENT);
        },

        openModalDeposit(currencyDefault) {
            this.$modal.replace(MODAL_DEPOSIT, {
                props: { currencyDefault },
                closeOnBackgroundClick: false,
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
.deposit-button-icon
    font-size 14px
    color #4cb65b
    margin-right 10px

.mutlicurrency
    min-width 220px
    padding 10px

    .mutlicurrency-container
        &:not(:first-child)
            margin-top 10px

        .mutlicurrency-title
            color #909090
            font-size 11px
            font-weight normal
            margin-left 5px

        .currency-list
            list-style none

            .currency-item
                margin-top 5px

.currency
    align-items center
    background-color #efefef
    border-radius 4px
    justify-content space-between
    display flex
    height 30px
    width 100%

    .currency-container
        align-items center
        display flex

    .currency-separator
        background-color #d8d8d8
        border none
        height 12px
        width 1px

    .currency-amount
        color #1b2029
        font-size 13px
        font-weight bold
        line-height .86
        margin-left 7px
        margin-right 7px

    .currency-icon
        color #a5a5a5
        width 30px
        display flex
        justify-content center
        align-items center

        &.currency-icon-bonus
            height 8px

    .currency-subtitle
        color #909090
        font-size 11px
        margin-left auto
        margin-right 10px

    .currency-dropdown
        font-size 10px

.open-account-management-button
    width 100%

:deep(.bonus-currency)
    margin-left 7px
    margin-right 7px

    .currency-amount
        font-size 12px
</style>
