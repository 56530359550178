<template lang="pug">
.home
    .main
        RouterView.aside(name="left")
        BetsContentLayout(
            @scroll="onScroll"
            v-bind="$attrs"
            v-scroll-remember="$options.name"
        )
        RouterView.aside.aside-right(name="right")

    RouterView.footer(name="footer")
</template>

<script>
import BetsContentLayout from '@desktop/pages/components/Bets/BetsContentLayout.vue';

export default {
    name: 'BetsLayout',

    components: {
        BetsContentLayout,
    },

    data() {
        return {
            isShowGarland: true,
        };
    },

    methods: {
        onScroll(event) {
            this.isShowGarland = event.target.scrollTop < 50;
        },
    },
};
</script>

<style lang="stylus" scoped>
@import '~@desktop/styles/mixins.styl'

.home
    .main
        container()

        display flex
        height 100%
        padding 3px 15px 0

        & > *
            padding-top 12px

.aside
  width $aside-width
  display flex
  flex-direction column
  position sticky
  top $header-bottom-height
  height 'calc(100vh - %s)' % $header-bottom-height

  &:not(:first-child)
    margin-left $size-lg

  &:not(:last-child)
    margin-right $size-lg

.aside-right
    overflow auto
    width 281px

    // чтоб кнопки саппорта не перекрывали блоки
    // из за бага в firefox тут этот хак
    &:after
        content ''
        display block
        flex-shrink 0
        height 20px
        width 100%

.main-content
    will-change scroll-position
</style>
