<template lang="pug">
VIcon(
    :icon="icon"
    v-bind="$attrs"
    v-on="$listeners"
)
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';

export default {
    name: 'VIconCurrency',

    components: {
        VIcon,
    },

    props: {
        currency: {
            type: String,
        },
    },

    computed: {
        icon() {
            return `currency/${this.currency ?? this.$store.getters.user?.currency}`;
        },
    },
};
</script>
