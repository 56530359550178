<template lang="pug">
.main-content(
    :class="{ 'scrollbar-transparent': hideScrollbar && !isScrolling }"
    @scroll="onScroll"
    v-on="$listeners"
)
    RouterView(name="slider")
    RouterView(key="$route.path")
</template>

<script>
export default {
    name: 'BetsContentLayout',

    data() {
        return {
            isScrolling: false,
            timeout: null,
        };
    },

    computed: {
        hideScrollbar() {
            return this.$route.params.match !== undefined;
        },
    },

    methods: {
        onScroll() {
            if (this.timeout) clearTimeout(this.timeout);
            if (!this.isScrolling) this.isScrolling = true;
            this.timeout = setTimeout(this.setStatic, 100);
        },

        setStatic() {
            this.isScrolling = false;
        },
    },
};
</script>

<style lang="stylus" scoped>
.main-content
    will-change scroll-position

    .block
        display block
</style>
