/* eslint no-underscore-dangle: [2, { "allow": ["_isPopStateHandlerInvoked", "_resetIsPopStateInvokedMark"] }] */

class PopstateEventController {
    constructor() {
        /**
         *  Be careful this listener should be executed before new VueRouter(...) and router.beforeEach.
         *  Otherwise it will be invoked after router hooks and component updates.
         */
        window.addEventListener('popstate', () => {
            this._isPopStateHandlerInvoked = true;
        });
    }

    /**
     * We cant receive router in constructor because constructor should be invoked before router is created.
     * This hook should be registered as the very first router hook.
     */
    registerRouterBeforeEachHook(router) {
        router.beforeEach((to, from, next) => {
            /**
             * Add "isPopstateAction" key for router, that indicates if router action
             * was trigger by "back"/"forward" browser button click.
             */
            router.isPopstateAction = this._isPopStateHandlerInvoked;
            this._resetIsPopStateInvokedMark();

            next();
        });
    }

    _resetIsPopStateInvokedMark() {
        this._isPopStateHandlerInvoked = false;
    }
}

const popstateControllerInstance = new PopstateEventController();
export default popstateControllerInstance;
