<template lang="pug">
v-modal( name="telegram-proxy" :title='text.title' )
    .container
        .setup-description(v-html="text['setup-description']")
        .proxy-table
            .country-row(v-for="server in serverList")
                div: .countryIcon: country-icon(:code='server.countryCode')
                div: .hostname {{ server.hostname }}
                div: .port {{ server.port }}
                div: .countryName {{ text.countryName[server.countryCode] }}
</template>

<script>
import { mapGetters } from 'vuex';

import CountryIcon from '@/components/CountryIcon.vue';

import VModal from '@desktop/components/VModal/VModal.vue';

export default {
    name: 'TelegramProxyModal',
    components: {
        VModal,
        CountryIcon,
    },
    data: () => ({
        serverList: [
            { countryCode: 'dk', hostname: '80.211.1.214', port: 8650 },
            { countryCode: 'ro', hostname: '94.177.213.231', port: 8650 },
            { countryCode: 'dk', hostname: '80.211.3.48', port: 8650 },
            { countryCode: 'ro', hostname: '77.81.229.44', port: 8650 },
            { countryCode: 'dk', hostname: '80.211.15.68', port: 8650 },
        ],
    }),
    computed: {
        ...mapGetters([
            'lang',
        ]),
        text() {
            return this.lang['telegram-proxy'].modal;
        },
    },
};
</script>

<style scoped lang="stylus">
    .container
        width 100%

        .setup-description
            margin 0 0 10px
            font-weight 200

            .hint
                font-weight 400
                display inline-block

        .proxy-table
            display table
            width 100%

            .country-row
                display table-row

                > div
                    display table-cell
                    padding-bottom 1px

                    > div
                        padding 4px 10px
                        background $base-color-3;

                        &.countryName
                            color #7f8997
                        &.hostname,
                        &.port
                            padding 4px 3px
                            letter-spacing 0

                &:first-of-type
                    > div:first-of-type > div
                        border-top-left-radius 4px
                    > div:last-of-type > div
                        border-top-right-radius 4px
                &:last-of-type
                    > div:first-of-type > div
                        border-bottom-left-radius 4px
                    > div:last-of-type > div
                        border-bottom-right-radius 4px
</style>
