<template lang="pug">
button.bets-navigation-middle-button-slide(v-on="$listeners")
    VIcon(:icon="direction === 'left' ? 'angle-left' : 'angle-right'")
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';

export default {
    name: 'BetsNavigationMiddleButtonSlide',

    components: {
        VIcon,
    },

    props: {
        direction: {
            type: String,
            default: 'left',
            validator: (value) => ['left', 'right'].includes(value),
        },
    },
};
</script>

<style scoped lang="stylus">
.bets-navigation-middle-button-slide
    display flex
    align-items center
    justify-content center
    width 21px
    height 28px
    border-radius 6px
    background-color alpha($dark-10, .15)
    font-size 16px
    flex-shrink 0
</style>
