<template lang="pug">
RouterLink.BonusLink(
    :to="{ name: $options.ROUTE_NAME.BONUSES }"
    custom
)
    template(#default="{ navigate }")
        div(@click="onClick(navigate, $event)")
            .BonusLink_circle
                VIcon.BonusLink_circle-icon(icon="present-bonus")

            .BonusLink_circle-text {{ text.promotions }}
            img.BonusLink_circle-img(:src="$options.presentWithLight")
</template>

<script>
import { mapGetters } from 'vuex';

import presentWithLight from '@/assets/images/bonus/present-with-light.png';
import VIcon from '@/components/Icons/VIcon.vue';

import ROUTE_NAME from '@desktop/constant/routeName';

export default {
    name: 'BonusLink',

    components: {
        VIcon,
    },

    computed: {
        ...mapGetters(['lang']),

        text() {
            return this.lang.help;
        },
    },

    methods: {
        onClick(navigate, event) {
            // Сделано намеренно, т.к. нужен порядок сначала эмита, потом перехода
            this.$emit('click');
            navigate(event);
        },
    },

    ROUTE_NAME,
    presentWithLight,
};
</script>

<style lang="stylus">
.BonusLink
    height 24px
    display flex
    align-items center
    border-radius 13px
    background-color alpha(#4e5d86, .3)
    border-bottom-left-radius 30px
    border-top-left-radius 30px
    position relative
    cursor pointer

    .BonusLink_circle
        height 28px
        width 28px
        background linear-gradient(86.37deg, #D062FF 2.96%, #7BB0FF 99.68%),
          linear-gradient(90deg, #ED6EA0 0%, #EC8C69 100%)
        box-shadow 0px 5px 15px rgba(201, 85, 255, .4)
        border-radius 50%
        display flex
        justify-content center
        align-items flex-end
        margin-right 10px
        padding-bottom 7px

        .BonusLink_circle-icon
            font-size 15px

    .BonusLink_circle-text
        font-weight 600
        font-size 10px
        line-height 13px
        margin-right 25px

    .BonusLink_circle-img
        position absolute
        height 54px
        transform rotate(-13deg)
        right -17px
        top -17px
        pointer-events none
</style>
