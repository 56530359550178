import {
    BETGAME_TV,
    TWAINSPORT_TV,
    FOOTER_LEADERBOARD,
    HEADER_CASINO_GAME,
    HEADER_INVEST,
    HEADER_RULES,
    HEADER_LEADERBOARD,
    LANG_ALLOWED_INVEST,
    LANG_ALLOWED_TV,
    LANG_RU,
    META_FORCE_CHANGE_LANG,
    META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY,
    META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST,
    META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER,
    META_NOT_ALLOWED_FOR_BLOCKABLE_DOMAIN,
    META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES,
    NO_SCROLL_CASINO_PAGE,
    PromotedGameIdEnum,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
    ROUTE_META_CUSTOM_FOOTER,
    ROUTE_META_CUSTOM_HEADER,
    ROUTE_META_CUSTOM_SUPPORT_PANEL,
    ROUTE_META_HIDE_SUPPORT_PANEL,
    ROUTE_META_HIDE_DIVIDENDS_MODAL,
    SERVICE_LIVE,
    SERVICE_PREMATCH,
    TVBET_GAME,
    TVBET_POKER,
} from '@/constant/constants';
import { AUTH } from '@/plugins/AuthRules';
import { LANG } from '@/plugins/LangRules';
import { TESTER } from '@/plugins/TesterRules';
import { CASINO_CATEGORIES } from '@/store/casino/enums';
import { getBetsComponentsProps } from '@/utils';

import ROUTE_NAME from '@desktop/constant/routeName';
import BetsLayout from '@desktop/layouts/Main/BetsLayout.vue';
import MainLayout from '@desktop/layouts/Main/Main.vue';
import BetsSubLayout from '@desktop/pages/BetsSubLayout.vue';
import CybersportBroadcasts from '@desktop/pages/components/Bets/Cybersport/CybersportBroadcasts.vue';

/* -- Async pages -- */
const AsideBlock = () => import('@desktop/pages/components/AsideBlock.vue');
const AsideSupportPanel = () => import('@desktop/layouts/components/SupportPanel/AsideSupportPanel.vue');
const BetsCustom = () => import('@desktop/pages/BetsCustom.vue');
const BetsAsideLeft = () => import('@desktop/pages/components/Bets/BetsAsideLeft.vue');
const BetsHistory = () => import('@desktop/pages/BetsHistory.vue');
const BetsSlider = () => import('@desktop/pages/components/Bets/BetsSlider.vue');
const Cases = () => import('@desktop/pages/Cases.vue');
const Casino = () => import('@desktop/pages/Casino.vue');
const CasinoCategory = () => import('@desktop/pages/CasinoCategory.vue');
const CasinoPromo = () => import('@desktop/pages/CasinoPromo.vue');
const CasinoGame = () => import('@desktop/pages/CasinoGame.vue');
const CasinoHome = () => import('@desktop/pages/CasinoHome.vue');
const CasinoJackpotPanel = () => import('@desktop/pages/components/Casino/CasinoJackpotPanel.vue');
const CasinoOwner = () => import('@desktop/pages/CasinoOwner.vue');
const CasinoWidget = () => import('@desktop/pages/CasinoWidget.vue');
const Home = () => import('@desktop/pages/Home.vue');
const Invest = () => import('@desktop/pages/Invest.vue');
const OAuth = () => import('@/components/OAuth/OAuthMain.vue');
const Results = () => import('@desktop/pages/Results.vue');
const Stats = () => import('@desktop/pages/Stats.vue');
const TvBet = () => import('@desktop/pages/TvBet.vue');
const TvBetGame = () => import('@desktop/pages/components/TvBet/TvBetGame.vue');
const TV = () => import('@desktop/pages/TV.vue');
const TVCinema = () => import('@desktop/pages/TVCinema.vue');
const Bets = () => import('@desktop/pages/Bets.vue');
const Cybersport = () => import('@desktop/pages/Cybersport.vue');
const CybersportAsideLeft = () => import('@desktop/pages/components/Bets/Cybersport/CybersportAsideLeft.vue');
const Leaderboard = () => import('@desktop/pages/Leaderboard.vue');
const JWTAuth = () => import('@/components/JWTAuth.vue');
const Rules = () => import('@desktop/pages/Rules.vue');
const Bonuses = () => import('@desktop/pages/Bonuses.vue');
const FreeMoney = () => import('@desktop/pages/FreeMoney.vue');
const PokerLanding = () => import('@desktop/pages/PokerLanding.vue');
const Footer = () => import('@desktop/layouts/components/Footer/Footer.vue');
/* -- Async pages -- */

/* -- Private functions and variables -- */

export default [
    {
        path: '',
        component: MainLayout,
        children: [
            {
                path: '/',
                redirect: '/bets/home',
            },
            {
                path: ':service(live|prematch)',
                redirect: '/bets/:service',
            },
            {
                path: 'new',
                redirect: '/bets/live',
            },
            {
                path: '/bets/new',
                redirect: '/bets/live',
            },
            {
                path: '/bets/new/:service(live|prematch)/:sport?/:category?/:tournament?/:match?',
                redirect: '/bets/:service/:sport?/:category?/:tournament?/:match?',
            },
            {
                path: '/registration',
                redirect: { path: '/bets/home', query: { open: 'register' } },
            },
            {
                path: '/casino/live',
                redirect: { name: ROUTE_NAME.CASINO_LIVE },
            },
            {
                path: '/games',
                redirect: { name: ROUTE_NAME.GAMES },
            },
            {
                path: '/games-1win',
                redirect: { name: ROUTE_NAME.GAMES_ONE_WIN },
            },
            {
                path: '/virtual-sport',
                redirect: { name: ROUTE_NAME.VIRTUAL_SPORT },
            },
            {
                path: '/virtual-sport/play/:id/:isDemo?',
                redirect: ({ params }) => ({ name: ROUTE_NAME.CASINO_GAME, params }),
            },

            {
                path: 'bets',
                alias: 'match',
                component: BetsSubLayout,
                children: [
                    {
                        path: '/',
                        redirect: { name: ROUTE_NAME.HOME },
                    },
                    {
                        name: ROUTE_NAME.BETS_CYBERSPORT_REDIRECT,
                        path: 'cybersport',
                        redirect: { name: ROUTE_NAME.BETS_CYBERSPORT, params: { service: SERVICE_LIVE } },
                    },
                    {
                        path: '',
                        component: BetsLayout,
                        children: [
                            {
                                name: ROUTE_NAME.BETS,
                                path: ':service(live|prematch)/:sport?/:category?/:tournament?/:match?',
                                components: {
                                    default: Bets,
                                    left: BetsAsideLeft,
                                    right: AsideBlock,
                                    slider: BetsSlider,
                                    footer: Footer,
                                },
                                props: {
                                    ...getBetsComponentsProps(),
                                    slider: (route) => ({ show: !route.params.match }),
                                },
                            },
                            {
                                name: ROUTE_NAME.BETS_CYBERSPORT,
                                path: 'cybersport/:service/:sportId?/:categoryId?/:tournamentId?/:matchId?',
                                components: {
                                    default: Cybersport,
                                    left: CybersportAsideLeft,
                                    right: AsideBlock,
                                    slider: CybersportBroadcasts,
                                    footer: Footer,
                                },
                                props: {
                                    ...getBetsComponentsProps(),
                                    slider: ({ params }) => ({
                                        // Если захотят показывать трансляции и на прематче,
                                        // надо просить бек, чтобы сделали ручки для киберспорта
                                        // в противном случае придется запрашивать много лишеней информации
                                        show: !params.matchId && params.service === SERVICE_LIVE,
                                        service: params.service,
                                    }),
                                },
                            },
                            {
                                name: ROUTE_NAME.BETS_OUTRIGHTS,
                                path: 'outrights/:sport?/:category?/:tournament?',
                                components: {
                                    default: Bets,
                                    left: BetsAsideLeft,
                                    right: AsideBlock,
                                    slider: BetsSlider,
                                    footer: Footer,
                                },
                                props: {
                                    ...getBetsComponentsProps(() => ({
                                        service: SERVICE_PREMATCH,
                                        outrightsOnly: true,
                                    })),
                                    slider: (route) => ({ show: !route.params.tournament }),
                                },
                            },
                            {
                                name: ROUTE_NAME.BETS_CUSTOM,
                                path: 'custom/:group?/:child?',
                                components: {
                                    default: BetsCustom,
                                    left: BetsAsideLeft,
                                    right: AsideBlock,
                                    slider: BetsSlider,
                                    footer: Footer,
                                },
                                props: {
                                    ...(['default', 'left'].reduce((accumulator, current) => Object.assign(accumulator, {
                                        [current]: (route) => {
                                            route.params.service = SERVICE_PREMATCH;
                                            return route.params;
                                        },
                                    }), {})),
                                    slider: () => ({ service: SERVICE_PREMATCH }),
                                },
                            },
                            {
                                name: ROUTE_NAME.HOME,
                                path: 'home',
                                components: {
                                    default: Home,
                                    footer: Footer,
                                },
                            },
                        ],
                    },
                ],
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                },
            },
            {
                name: ROUTE_NAME.LEADERBOARD,
                path: 'leaderboard',
                component: Leaderboard,
                props: (route) => ({
                    showHistory: Boolean(route.query.history),
                }),
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_LEADERBOARD,
                    [ROUTE_META_CUSTOM_FOOTER]: FOOTER_LEADERBOARD,
                    [TESTER]: true,
                },
            },
            {
                name: ROUTE_NAME.BETS_HISTORY,
                path: 'bets-history',
                component: BetsHistory,
                meta: {
                    [AUTH]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                },
            },
            {
                name: ROUTE_NAME.BETS_RESULT,
                path: 'results',
                component: Results,
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                },
            },
            {
                path: 'tvbet',
                component: TvBet,
                children: [
                    {
                        path: '',
                        name: ROUTE_NAME.TVBET,
                        redirect: '/tvbet/play',
                    },
                    {
                        name: ROUTE_NAME.TVBET_GAME,
                        path: 'play',
                        component: TvBetGame,
                        props: () => ({ gameType: TVBET_GAME }),
                        meta: {
                            [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_CASINO_GAME,
                            [ROUTE_META_HIDE_SUPPORT_PANEL]: true,
                        },
                    },
                ],
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
                    [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
                },
            },
            {
                path: 'betgames',
                component: TvBet,
                children: [
                    {
                        path: '',
                        name: ROUTE_NAME.BETGAMES,
                        redirect: '/betgames/play',
                    },
                    {
                        name: ROUTE_NAME.BETGAMES_GAME,
                        path: 'play',
                        component: TvBetGame,
                        props: () => ({ gameType: BETGAME_TV }),
                        meta: {
                            [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_CASINO_GAME,
                            [ROUTE_META_HIDE_SUPPORT_PANEL]: true,
                        },
                    },
                ],
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
                    [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
                },
            },
            {
                path: 'twain-sport',
                component: TvBet,
                children: [
                    {
                        path: '',
                        name: ROUTE_NAME.TWAINSPORT,
                        redirect: '/twain-sport/play',
                    },
                    {
                        name: ROUTE_NAME.TWAINSPORT_GAME,
                        path: 'play',
                        component: TvBetGame,
                        props: () => ({ gameType: TWAINSPORT_TV }),
                        meta: {
                            [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_CASINO_GAME,
                            [ROUTE_META_HIDE_SUPPORT_PANEL]: true,
                        },
                    },
                ],
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
                    [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
                },
            },
            {
                path: 'poker',
                name: ROUTE_NAME.POKER,
                component: PokerLanding,
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                },
            },
            {
                path: 'poker',
                component: TvBet,
                children: [
                    {
                        name: ROUTE_NAME.POKER_GAME,
                        path: 'play',
                        component: TvBetGame,
                        props: () => ({ gameType: TVBET_POKER }),
                        meta: {
                            [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_CASINO_GAME,
                            [ROUTE_META_HIDE_SUPPORT_PANEL]: true,
                        },
                    },
                ],
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
                    [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
                },
            },
            {
                path: 'cases',
                name: ROUTE_NAME.CASES_LIST,
                component: Cases,
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                },
            },
            {
                path: 'case/:caseTypeId',
                name: ROUTE_NAME.CASE,
                component: Cases,
                props: (route) => ({ caseTypeId: parseInt(route.params.caseTypeId, 10) || 0 }),
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
                },
            },
            {
                path: 'casino',
                component: Casino,
                children: [
                    {
                        name: ROUTE_NAME.CASINO,
                        path: '/',
                        components: {
                            default: CasinoHome,
                            jackpot: CasinoJackpotPanel,
                        },
                    },
                    {
                        name: ROUTE_NAME.CASINO_FAVORITES,
                        path: 'favourite',
                        components: {
                            default: CasinoCategory,
                            jackpot: CasinoJackpotPanel,
                        },
                        props: {
                            default: { categoryId: CASINO_CATEGORIES.FAVORITES },
                        },
                    },
                    {
                        name: ROUTE_NAME.CASINO_WIDGET,
                        path: 'widget/:widgetId',
                        components: {
                            default: CasinoWidget,
                            jackpot: CasinoJackpotPanel,
                        },
                        props: {
                            default: (route) => ({ widgetId: route.params.widgetId }),
                        },
                    },
                    {
                        name: ROUTE_NAME.CASINO_OWNER,
                        path: 'provider/:ownerName',
                        components: {
                            default: CasinoOwner,
                            jackpot: CasinoJackpotPanel,
                        },
                        props: {
                            default: (route) => ({ ownerName: route.params.ownerName }),
                        },
                    },
                    ...[
                        {
                            name: ROUTE_NAME.CASINO_LIVE,
                            categoryId: CASINO_CATEGORIES.LIVE,
                        },
                        {
                            name: ROUTE_NAME.GAMES,
                            categoryId: CASINO_CATEGORIES.OTHER,
                        },
                        {
                            name: ROUTE_NAME.VIRTUAL_SPORT,
                            categoryId: CASINO_CATEGORIES.VIRTUAL_SPORTS,
                        },
                        {
                            name: ROUTE_NAME.GAMES_ONE_WIN,
                            categoryId: CASINO_CATEGORIES.ONE_WIN,
                        },
                    ].map(({ name, categoryId }) => ({
                        name,
                        path: `list/${categoryId}`,
                        components: {
                            default: CasinoCategory,
                            jackpot: CasinoJackpotPanel,
                        },
                        props: {
                            default: { categoryId },
                        },
                    })),
                    {
                        name: ROUTE_NAME.CASINO_LIST,
                        path: 'list/:category?',
                        components: {
                            default: CasinoCategory,
                            jackpot: CasinoJackpotPanel,
                        },
                        props: {
                            default: (route) => ({ categoryId: +route.params.category || CASINO_CATEGORIES.POPULAR }),
                        },
                    },
                    {
                        name: ROUTE_NAME.CASINO_PROMO,
                        path: 'promo/:id?',
                        components: {
                            default: CasinoPromo,
                            jackpot: CasinoJackpotPanel,
                        },
                        props: {
                            default: (route) => ({ id: Number(route.params.id) }),
                        },
                    },
                    {
                        path: 'play/spribe_aviator/:any?',
                        redirect: { name: ROUTE_NAME.CASINO_GAME_AVIATOR },
                    },
                    {
                        name: ROUTE_NAME.CASINO_GAME_AVIATOR,
                        path: 'play/aviator',
                        redirect: {
                            name: ROUTE_NAME.CASINO_GAME,
                            params: { id: 'aviator' },
                        },
                    },
                    {
                        name: ROUTE_NAME.CASINO_GAME_FANTASY_SPORT,
                        path: 'play/scout_fantasy',
                        redirect: {
                            name: ROUTE_NAME.CASINO_GAME,
                            params: { id: PromotedGameIdEnum.SCOUT_FANTASY },
                        },
                    },
                    {
                        name: ROUTE_NAME.CASINO_GAME,
                        path: 'play/:id/:isDemo?',
                        component: CasinoGame,
                        meta: {
                            [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_CASINO_GAME,
                            [ROUTE_META_HIDE_SUPPORT_PANEL]: true,
                            [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
                            [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
                            [NO_SCROLL_CASINO_PAGE]: true,
                        },
                    },
                ],
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                },
            },
            {
                path: 'oauth/:type',
                name: ROUTE_NAME.OAUTH,
                component: OAuth,
                meta: {
                },
            },
            {
                path: 'auth/token/:token',
                name: ROUTE_NAME.AUTH_TOKEN,
                component: JWTAuth,
                meta: {
                },
            },
            {
                path: 'tv',
                name: ROUTE_NAME.TV,
                component: TV,
                meta: {
                    [LANG]: LANG_ALLOWED_TV,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [META_NOT_ALLOWED_FOR_BLOCKABLE_DOMAIN]: true,
                },
            },
            {
                path: 'tv/:cinemaId',
                name: ROUTE_NAME.TV_CINEMA,
                component: TVCinema,
                props: (route) => ({
                    cinemaId: route.params.cinemaId.toString(),
                }),
                meta: {
                    [LANG]: LANG_ALLOWED_TV,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
                    [META_NOT_ALLOWED_FOR_BLOCKABLE_DOMAIN]: true,
                    [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
                },
            },
            {
                path: '/tv/:cinemaId/view',
                redirect: { name: ROUTE_NAME.TV_CINEMA },
            },
            {
                path: 'invest',
                name: ROUTE_NAME.INVEST,
                component: Invest,
                meta: {
                    [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_INVEST,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                    [META_FORCE_CHANGE_LANG]: {
                        allowed: LANG_ALLOWED_INVEST,
                        changeTo: LANG_RU,
                    },
                    [ROUTE_META_CUSTOM_SUPPORT_PANEL]: AsideSupportPanel,
                },
            },
            {
                path: 'rule/:ruleId?',
                redirect: (to) => ({ ...to, name: ROUTE_NAME.RULES }),
            },
            {
                path: '/rules.html',
                redirect: (to) => ({ ...to, name: ROUTE_NAME.RULES }),
            },
            {
                path: 'rules/:ruleId?',
                name: ROUTE_NAME.RULES,
                component: Rules,
                meta: {
                    [ROUTE_META_CUSTOM_HEADER]: HEADER_RULES,
                    [ROUTE_META_HIDE_SUPPORT_PANEL]: true,
                },
                props: (route) => (route.params.ruleId ? { ruleId: Number(route.params.ruleId) } : null),
                beforeEnter: (to, from, next) => {
                    if (to.hash && !to.params.ruleId) {
                        next({ name: ROUTE_NAME.RULES, params: { ruleId: to.hash.slice(1) } });
                    } else {
                        next();
                    }
                },
            },

            {
                path: 'bonuses',
                name: ROUTE_NAME.BONUSES,
                component: Bonuses,
                meta: {
                    [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
                },
            },
            {
                path: '/free-money',
                name: ROUTE_NAME.FREE_MONEY,
                component: FreeMoney,
                meta: {
                },
            },
        ],
    },
    {
        name: ROUTE_NAME.STATS,
        path: '/stats/:externalId?',
        component: Stats,
        props: ({ params }) => ({
            externalId: params.externalId,
        }),
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
        },
    },
    {
        path: '*',
        redirect: '/',
    },
];
