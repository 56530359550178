<template lang="pug">
.CybersportHotMatch(v-if="match")
    .CybersportHotMatch__top
        .CybersportHotMatch__title
            LiveDot.CybersportHotMatch__title-dot
            span {{ text.broadcast }}

        .CybersportHotMatch__buttons-slide(v-if="showButtonSlide")
            ButtonSlide.CybersportHotMatch__button-slide(@click="$emit('slidePrev')")
            ButtonSlide.CybersportHotMatch__button-slide(
                @click="$emit('slideNext')"
                direction="right"
            )

    .CybersportHotMatch__center
        .CybersportHotMatch__team
            .CybersportHotMatch__team-img-container
                CybersportTeamIcon.team-image(
                    hot
                    :eSportTeamId="match.homeTeamId"
                )
                CybersportTeamIcon.team-image.CybersportHotMatch__team-img-blur(
                    hot
                    :eSportTeamId="match.homeTeamId"
                )

            .CybersportHotMatch__team-name {{ teamNames[0] }}

        .CybersportHotMatch__info
            .CybersportHotMatch__info-datetime
                .CybersportHotMatch__info-date {{ match.date.date }} {{ monthName }}
                .CybersportHotMatch__info-time {{ match.date.time }}

            .CybersportHotMatch__info-score {{ matchScore }}

        .CybersportHotMatch__team
            .CybersportHotMatch__team-img-container
                CybersportTeamIcon.team-image(
                    hot
                    :eSportTeamId="match.awayTeamId"
                )
                CybersportTeamIcon.team-image.CybersportHotMatch__team-img-blur(
                    hot
                    :eSportTeamId="match.awayTeamId"
                )

            .CybersportHotMatch__team-name {{ teamNames[1] }}

    table.CybersportHotMatch__odds(v-if="oddBaseConfig")
        tbody
            tr
                template(
                    v-for="config in oddBaseConfig"
                )
                    td.CybersportHotMatch__odd-header(v-if="config.hasSpecialValue") {{ groupName(config.groups[0]) }}
                    td.CybersportHotMatch__odd-header(
                        v-for="o in config.outcomes"
                        :key="o.id"
                    ) {{ $filters.locale(o.translations) }}

            tr.CybersportHotMatch__odds-row
                template(v-for="c in oddBaseConfig")
                    td.CybersportHotMatch__odd(
                        v-for="c in oddConfigList(c)"
                        :key="c.key"
                        :class="getOddClass(c)"
                        @click="toggleBaseCoupon(c)"
                    )
                        .CybersportHotMatch__odd-coefficient(
                            v-watch:[c.value]="setDifferenceClass"
                        ) {{ c.value || '-' }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { getTableHeader } from '@/utils/match';
import formatterOddConfig from '@/xbet/formatter/oddConfig';
import { score } from '@/xbet/lib/scoreFormatter';
import setDifferenceClass from '@/xbet/lib/setDifferenceClass';

import ButtonSlide from '@desktop/components/ButtonSlide.vue';
import LiveDot from '@desktop/components/LiveDot.vue';
import CybersportTeamIcon from '@desktop/pages/components/Bets/Cybersport/CybersportTeamIcon.vue';

export default {
    components: {
        ButtonSlide,
        LiveDot,
        CybersportTeamIcon,
    },

    inject: ['$filters'],

    props: {
        match: {
            type: Object,
        },

        showButtonSlide: {
            type: Boolean,
        },
    },

    computed: {
        ...mapGetters('xbet/oddBaseConfig', ['getOddBaseConfigForTournament']),
        ...mapGetters(['lang']),
        ...mapGetters('coupon', ['checkCouponAdded']),

        text() {
            return this.lang.esport['hot-match'];
        },

        oddBaseConfig() {
            return this.getOddBaseConfigForTournament(
                this.match.service,
                this.match.sportId,
                this.match.tournamentId,
            );
        },

        oddList() {
            const oddMap = this.$store.getters[`xbet/${this.match.service}/oddMapByMatchId`][this.match.id];

            return Object.values(oddMap || {})
                .filter((odd) => odd.outcome !== null);
        },

        monthName() {
            return this.lang[`month_${this.match.date.month}`];
        },

        tableHeader() {
            return getTableHeader(this.match.sport, this.match);
        },

        teamNames() {
            return [
                this.match.teamNameFirst,
                this.match.teamNameSecond,
            ];
        },

        matchScore() {
            return score(this.match.matchScore);
        },
    },

    methods: {
        ...mapActions('coupon', ['toggleCoupon']),

        groupName(groupId) {
            return this.$store.getters['xbet/oddGroups/getOddGroupMap'][groupId]?.name;
        },

        oddConfigList(config) {
            let specialValue = null;

            const outcomes = config.outcomes.map((outcome) => {
                const odd = this.oddList.find((o) => {
                    const groupMatch = config.groups.find((group) => +o.groupId === group);
                    const outcomeMatch = o.outCome === outcome.outcome;
                    const specialValueMatch = (
                        (!config.hasSpecialValue || specialValue === null)
                        || (o.specialValue === specialValue)
                    );

                    return groupMatch && outcomeMatch && specialValueMatch;
                });

                if (config.hasSpecialValue) {
                    specialValue = odd?.specialValue;
                }

                return formatterOddConfig({
                    odd, config, outcome, isSpecial: config.hasSpecialValue,
                });
            });

            if (config.hasSpecialValue) {
                return [
                    formatterOddConfig({ config, specialValue }),
                    ...outcomes,
                ];
            }

            return outcomes;
        },

        toggleBaseCoupon({ odd, special }) {
            if (!odd || special) return;

            this.toggleCoupon(odd);
        },

        getOddClass(c) {
            return {
                'CybersportHotMatch__odd--added': this.checkCouponAdded(c.key),
                'CybersportHotMatch__odd--disabled': c.disabled || !c.value,
                'CybersportHotMatch__odd--special': c.isSpecial,
            };
        },

        setDifferenceClass,
    },
};
</script>

<style lang="stylus">
.CybersportHotMatch
    display flex
    flex-direction column
    justify-content space-between

    &__top
        border-bottom 1px solid alpha(#b3c9f6, .1)
        width 100%
        padding-bottom 12px
        margin-bottom 20px
        display flex
        align-items center
        min-height 41px

    &__title
        font-size 14px
        font-weight bold
        display flex
        align-items center

    &__title-dot
        margin-right 6px

    &__buttons-slide
        display flex
        margin-left auto

    &__button-slide:not(:last-child)
        margin-right 5px

    &__center
        display flex
        justify-content space-evenly
        margin-bottom 15px

    &__team
        display flex
        flex-direction column
        align-items center
        flex-basis 0
        flex-grow 1
        max-width 100%

    &__team-img-container
        position relative

        .team-image
            width 60px
            height 60px
            max-width 100%

    &__team-img-blur
        position absolute
        left 0
        top 0
        filter blur(40px)

    &__team-name
        font-size 14px
        font-weight bold
        text-align center
        margin-top 10px

    &__info
        display flex
        flex-direction column
        align-items center
        flex-shrink 0
        margin-top 18px

    &__info-datetime
        display flex
        align-items flex-end

    &__info-date
        font-size 11px
        margin-right 5px
        color alpha(#fff, .5)

    &__info-time
        font-size 12px

    &__info-score
        font-size 26px
        font-weight 900
        font-family $font-family-heading

    &__odds
        width 100%
        min-height 60px

    &__odds-name
        opacity 0.7
        font-size 11px
        letter-spacing -0.1px
        text-align center
        color alpha(#b1d1ff, .7)
        padding-bottom 8px

    &__odds-row
        height 39px

    &__odds-item:first-child
        border-top-left-radius 10px
        border-bottom-left-radius 10px

    &__odds-item:last-child
        border-top-right-radius 10px
        border-bottom-right-radius 10px

    &__odd
        background-color #dce4f9
        text-align center
        width 48px
        color: #0a101e
        font-size 12px
        font-weight bold
        cursor pointer
        padding 0 2px

        &:first-child
            border-top-left-radius 10px
            border-bottom-left-radius 10px

        &:last-child
            border-top-right-radius 10px
            border-bottom-right-radius 10px

        &:not(&--added):not(&--disabled):hover
            .CybersportHotMatch__odd-coefficient
                background-image linear-gradient(138deg, alpha(#108de7, 0.3) -63%, alpha(#0855c4, 0.3) 97%)

        &--added
            .CybersportHotMatch__odd-coefficient
                color #fff
                background-image linear-gradient(138deg, #108de7 -63%, #0855c4 97%)

        &--disabled
            cursor default
            color alpha(#0a101e, 0.5)

    &__odd-coefficient
        height 27px
        line-height 27px
        border-radius 6.5px
        user-select none
        display inline-block
        padding 0 5px

    &__odd-header
        color #b1d1ff
        font-size 11px
        text-align center
        width 48px
</style>
