<template lang="pug">
#header-rules
    .header-rules-container
        .header-rules
            .level-item
                .logo-container
                    RouterLink.logo-link(:to="{ name: ROUTE_NAME.HOME }")
                        Logo(small)

            .level-item
                .header-line-right--item(v-if="isHeaderDesktopAppsBlockShown")
                    .df
                        HeaderDesktopApps
                        .spacer
                        HeaderMobileApps

                .header-line-right--item(v-if="isShowChangeLanguage")
                    ChangeLanguage

                .header-line-right--item
                    HeaderSupportPanel
</template>

<script>
import { mapGetters } from 'vuex';

import Logo from '@/components/Logo/Logo.vue';
import PWA from '@/plugins/InstallPWA';

import ChangeLanguage from '@desktop/components/Navigation/ChangeLanguage.vue';
import ROUTE_NAME from '@desktop/constant/routeName';
import HeaderSupportPanel from '@desktop/layouts/components/SupportPanel/HeaderSupportPanel.vue';

import HeaderDesktopApps from './apps/HeaderDesktopApps.vue';
import HeaderMobileApps from './apps/HeaderMobileApps.vue';

export default {
    name: 'HeaderTop',

    components: {
        Logo,
        HeaderDesktopApps,
        HeaderMobileApps,
        ChangeLanguage,
        HeaderSupportPanel,
    },

    data: () => ({
        ROUTE_NAME,
        PWA,
    }),

    computed: {
        ...mapGetters([
            'isDesktopApp',
            'isPwaIgnored',
            'isShowChangeLanguage',
            'lang',
        ]),
        text() {
            return this.lang.rules;
        },
        isPwa() {
            return this.$platform.pwa.any;
        },
        isHeaderDesktopAppsBlockShown() {
            return !this.isPwaIgnored && !this.isDesktopApp && !this.isPwa;
        },
    },
};
</script>

<style lang="stylus" scoped>
#header-rules
    display flex
    justify-content center
    width 100%
    position sticky
    top 0
    z-index 100
    min-width $min-width

.header-rules-container
    height 47px
    width 1008px

    .header-rules
        display flex
        align-items center
        border-radius 0 0 10px 10px
        background-image linear-gradient(to right, #1e283f, mix(#141b2e, $base-color-8, 60%))
        padding 0 15px
        height 100%
        justify-content space-between

        .header-line-right--item
            &:not(:last-child)
                margin-right 21px
                position relative

                &::before
                    content ''
                    display block
                    height 14px
                    width 1px
                    background alpha($white, 0.2)
                    position absolute
                    right -10px
                    top calc(50% - 7px)

    .spacer
        display inline-block
        margin 0 5px

.logo-container
    display flex
    align-items center
    justify-content center
    width 60px
    height 24px

    .logo-link
        display flex
        align-items center
        justify-content center
        width 100%
        height 100%

        :deep(.logo)
            height 38px
</style>
