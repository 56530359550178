<template lang="pug">
TooltipHover(
    placement="bottom"
    :offset="[0, 15]"
)
    .header-mobile-apps
        .app-button
            VIconSvgGradient(
                icon="android"
                gradient="android-pwa-icon-gradient"
                gradientTagName="path"
            )
        .app-button
            VIconSvgGradient(
                icon="ios"
                gradient="ios-pwa-icon-gradient"
                gradientTagName="path"
            )

    template(#tooltip)
        MobilePWAInfoTooltip
</template>
<script>
import VIconSvgGradient from '@/components/Icons/VIconSvgGradient.vue';
import TooltipHover from '@/components/Tooltip/TooltipHover.vue';

import MobilePWAInfoTooltip from '@desktop/tooltips/MobilePWAInfoTooltip.vue';

export default {
    name: 'HeaderMobileApps',
    components: {
        VIconSvgGradient,
        TooltipHover,
        MobilePWAInfoTooltip,
    },
};
</script>

<style scoped lang="stylus">
.header-mobile-apps
    display flex
    border-radius 6px
    backdrop-filter blur(5px)
    background-color alpha(#4e5d86, .3)
    padding 5px 8px
    font-size 14px
    .app-button
        display block
        margin-right 6px
        &:last-of-type
            margin-right 0
</style>
