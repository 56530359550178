<template lang="pug">
.mobile-pwa-info
    VImage(:image="image")

    .mobile-pwa-info-text
        PwaInfoDescription(:rawMessage="text.message")
            template(#default="{ message, isLoading }")
                div
                    Spinner.mobile-pwa-info-spinner(v-if="isLoading")
                    div(
                        v-else
                        v-html="message"
                    )
</template>

<script>
import { mapGetters } from 'vuex';

import PwaInfoDescription from '@/components/PwaInfoDescription.vue';
import Spinner from '@/components/Spinner.vue';
import VImage from '@/components/VImage.vue';
import { PLATFORM_ANDROID, PLATFORM_IOS } from '@/constant/constants';

import PWAImageAndroidEn from '@desktop/assets/images/pwa_android_en.png';
import PWAImageAndroidRu from '@desktop/assets/images/pwa_android_ru.png';
import PWAImageIOSEn from '@desktop/assets/images/pwa_ios_en.png';
import PWAImageIOSRu from '@desktop/assets/images/pwa_ios_ru.png';

export default {
    name: 'MobilePWAInfoTooltip',

    components: {
        Spinner,
        PwaInfoDescription,
        VImage,
    },

    props: {
        platform: {
            type: String,
            validator: (val) => [PLATFORM_ANDROID, PLATFORM_IOS].includes(val),
            default: PLATFORM_ANDROID,
        },
    },

    computed: {
        ...mapGetters([
            'lang',
            'currentLang',
            'currency',
        ]),

        imageAndroid() {
            return {
                ru: PWAImageAndroidRu,
                en: PWAImageAndroidEn,
            }[this.currentLang] || PWAImageAndroidEn;
        },

        imageIOS() {
            return {
                ru: PWAImageIOSRu,
                en: PWAImageIOSEn,
            }[this.currentLang] || PWAImageIOSEn;
        },

        image() {
            return {
                [PLATFORM_ANDROID]: this.imageAndroid,
                [PLATFORM_IOS]: this.imageIOS,
            }[this.platform];
        },

        text() {
            return this.lang.MobilePWAInfoTooltip;
        },
    },
};
</script>

<style lang="stylus" scoped>
.mobile-pwa-info
    background #fff
    color black
    border-radius 10px
    padding 5px
    width: 250px

.mobile-pwa-info-text
    font-size 13px
    line-height 1.23
    text-align center
    max-width 230px
    margin-bottom 9px
    margin-top 10px

.mobile-pwa-info-spinner
    color black
</style>
