<template lang="pug">
.LiveDot
</template>

<script>
export default {
    name: 'LiveDot',
};
</script>

<style scoped lang="stylus">
.LiveDot
    animation pulse 2s infinite
    background #fa203b
    border-radius 50%
    display block
    height 4px
    width 4px

    @keyframes pulse
        0%
            box-shadow 0 0 0 0 alpha(#fa203b, .5)

        70%
            box-shadow 0 0 0 2px alpha(#fa203b, .3)

        100%
            box-shadow 0 0 0 2px transparent
</style>
