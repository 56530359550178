<template>
  <BaseInfo
    :title="formattedTitle"
    :message="formattedMessage"
    :link-text="text.link"
    @link-click="clickLinkHandler"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import { formatString } from '@/plugins/LanguageTools';

import BaseInfo from '@desktop/components/Info/BaseInfo.vue';
import { MODAL_CASINO_CASHBACK } from '@desktop/constant/modalName';
import ROUTE_NAME from '@desktop/constant/routeName';

const CASHBACK_PERCENT = 30;

export default {
    components: {
        BaseInfo,
    },

    inject: ['$modal'],

    props: {
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapGetters([
            'langCommon',
        ]),

        text() {
            return this.langCommon.paymentMethod.cashback.info;
        },

        formattedTitle() {
            return formatString(
                this.text.title,
                CASHBACK_PERCENT,
            );
        },

        formattedMessage() {
            return formatString(
                this.text.message,
                CASHBACK_PERCENT,
            );
        },
    },

    methods: {
        clickLinkHandler() {
            if (this.$router.currentRoute.name !== ROUTE_NAME.BONUSES) {
                this.$router.push({ name: ROUTE_NAME.BONUSES });
            }
            this.$modal.open(MODAL_CASINO_CASHBACK);
        },
    },
};
</script>
