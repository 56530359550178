<template lang="pug">
component(
    v-if="toolBarPanel"
    :is="toolBarPanel"
)
</template>

<script>
import ROUTE_NAME from '@desktop/constant/routeName';
import CasinoGameToolbarPanel from '@desktop/layouts/components/ToolbarPanel/CasinoGameToolbarPanel.vue';

export default {
    components: {
        CasinoGameToolbarPanel,
    },

    computed: {
        toolBarPanel() {
            return this.$route.name === ROUTE_NAME.CASINO_GAME ? CasinoGameToolbarPanel : undefined;
        },
    },
};
</script>
