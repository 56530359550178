<template lang="pug">
.header-balance(:class="{'header-balance--balance-hidden': !showBalance}")
    .header-balance__top
        template(v-if="showBalance")
            .header-balance__balance
                VDropdown(
                    angle
                    hoverable
                    align="center"
                )
                    .header-balance__dropdown(slot="trigger")
                        .df-aic.header-balance-dropdown__top
                            .header-balance__title
                                | {{ text.balance }}
                            VIcon.header-balance__angle-icon(
                                icon="angle-down"
                            )
                        .header-balance__value
                            | {{ $filters.float(balance) }}
                            | {{ $filters.currency(currency) }}
                    Multicurrency
            .header-balance__bonus
                VDropdown(
                    angle
                    hoverable
                    align="center"
                )
                    BonusAmount(
                        slot="trigger"
                        :bonusAmount="bonusAmount"
                        :bonusCasino="bonusCasino"
                        vertical
                        showWithZero
                    )
                    BonusInfo
        template(v-else)
            span {{ clippedName }}
            VDropdown(
                angle
                hoverable
                align="center"
            )
                Multicurrency
                .header-balance__currency-picker(slot="trigger")
                    | {{ $filters.currency(currency) }}
                    VIcon.header-balance__currency-picker-chevron(icon="angle-down")

    .header-balance__username(v-if="!showBalance")
        span ID {{ user.id }}
</template>

<script>
import { mapGetters } from 'vuex';

import BonusAmount from '@/components/BonusAmount.vue';
import VIcon from '@/components/Icons/VIcon.vue';
import { VDropdown, VDropdownItem } from '@/components/VDropdown/index';

import BonusInfo from '@desktop/components/Info/BonusInfo.vue';
import Multicurrency from '@desktop/layouts/components/Header/Multicurrency.vue';

export default {
    name: 'HeaderUserBalance',

    components: {
        BonusAmount,
        BonusInfo,
        Multicurrency,
        VDropdown,
        VDropdownItem,
        VIcon,
    },

    inject: ['$filters'],

    computed: {
        ...mapGetters([
            'showVipDomain',
            'currency',
            'balance',
            'showBalance',
            'user',
            'hasBonus',
            'bonusAmount',
            'bonusCasino',
        ]),
        clippedName() {
            let { name } = this.user;
            [name] = name.split(' ');
            if (name.length <= 15) return name;
            return `${name.slice(0, 15)}...`;
        },
        text() {
            return this.$store.getters.lang.header;
        },
    },
};
</script>

<style lang="stylus" scoped>
.header-balance
    display flex
    margin-right 15px
    align-items center

    &.header-balance--balance-hidden
        align-items flex-end
        flex-direction column

        .header-balance__top
            padding-top 2px
            margin-bottom 5px

    :deep(.currency-amount)
        font-weight 500
        font-size 11px
        padding-right calc(1.1em + 4px)

    .header-balance__bonus
        &.bonus-icon
            height 1.1em
            width 1.1em
            top calc(50% - .6em)

.header-balance__title
    color alpha(#fff, .5)
    line-height 1.3
    font-size 10px
    align-self flex-end

.header-balance__angle-icon
    margin-left 5px
    color alpha(#fff, .8)
    height 13px
    transform translateZ(0px)

.header-balance__dropdown
    display flex
    flex-direction column

.header-balance-dropdown__top
    align-self flex-end
    margin-bottom 1px

.header-balance__balance
    padding-right 11px

.header-balance__bonus
    display flex
    flex-flow row nowrap
    position relative
    padding-left 12px

    &::before
        content ''
        position absolute
        left 0
        top calc(50% - 13px)
        height 26px
        width 1px
        background alpha(#fff, .1)

.header-balance__value
    font-weight bold
    font-size 14px
    white-space nowrap
    text-align right

.header-balance__bonus-divider
    width 1px
    height 10px
    background-color alpha(#fff, .1)
    margin 1px 5px

.header-balance__username
    font-size 11px
    font-weight 500
    color alpha(#d4dbeb, .5)

.header-balance__top
    display flex
    flex-flow row nowrap
    font-size 13px
    font-weight 600

.header-balance__sum
    margin-right 10px
    white-space nowrap

.header-balance__bonus-icon
.header-balance__angle-icon
    cursor pointer

.header-balance__bonus-dropdown
    margin-left 9px

.header-balance__bonus-dropdown-icon
    opacity 0.5
    color #d4dbeb
    padding 0 1px 2px 0

.header-balance__currency-picker
    width 27px
    height 14px
    border-radius 5px
    background white
    margin-left 5px
    display flex
    color black
    font-size 11px
    font-weight 600
    align-items center
    justify-content space-between
    padding 2px 5px 0 5px

.header-balance__currency-picker-chevron
    opacity 0.6
    color #161e32
    font-size 9px
</style>
