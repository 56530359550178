<template lang="pug">
VDropdown(
    hoverable
    :align="dropdownAlign"
    :class="{ footer: isFooterTheme }"
)
    VButton.country-button(
        slot="trigger"
        size="sm"
        type="button"
    )
        .country-label {{ currentCountry.visualKey }}

        VIcon.angle-down(
            v-if="!isFooterTheme"
            icon="angle-down"
            inline
        )

        .country-icon-wrapper
            CountryIcon.country-icon(
                :code="currentCountry.oneWinKey"
                rounded
            )

    VDropdownItem.item(
        v-for="country in otherCountries"
        :key="country.label"
        @click="changeLanguage(country.oneWinKey)"
    )

        .country-label {{ country.visualKey }}

        .country-icon-wrapper
            CountryIcon.country-icon(
                :code="country.oneWinKey"
                rounded
            )
</template>

<script>
import { mapGetters } from 'vuex';

import CountryIcon from '@/components/CountryIcon.vue';
import VIcon from '@/components/Icons/VIcon.vue';
import VButton from '@/components/VButton.vue';
import { VDropdown, VDropdownItem } from '@/components/VDropdown/index';
import { LangItemsEnum } from '@/constant/constants';

export default {
    name: 'ChangeLanguage',

    components: {
        CountryIcon,
        VButton,
        VDropdown,
        VDropdownItem,
        VIcon,
    },

    props: {
        isFooterTheme: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters([
            'currentLang',
        ]),

        currentCountry() {
            return LangItemsEnum.getItemByOneWinKey(this.currentLang);
        },

        otherCountries() {
            return LangItemsEnum.sortedValuesByOneWinKey.filter(
                ({ oneWinKey }) => oneWinKey !== this.currentCountry.oneWinKey,
            );
        },

        dropdownAlign() {
            return this.isFooterTheme ? 'top-right' : 'right';
        },
    },

    methods: {
        changeLanguage(lang) {
            this.$store.dispatch('setCurrentLanguage', { lang });
        },
    },
};
</script>

<style lang="stylus" scoped>
.country-button
    width 60px
    height 24px
    min-height 24px
    padding 0
    background alpha(#4e5d86, 0.3)

    .angle-down
        margin 0 8px 0 4px
        font-size 7px
        fill alpha(#fff, .5)

    .country-label
        font-size 10px

.country-label
    text-transform uppercase
    font-size 12px
    font-weight 600

// fix for safari
.country-icon-wrapper
    overflow hidden
    border-radius 50%
    height 16px
    width 16px
    display flex
    align-items center
    justify-content center

.country-icon
    display block
    height 17px
    max-width 18px
    flex none

:deep(.dropdown-item)
    justify-content space-between

.footer
    .country-button
        background linear-gradient(92.58deg, #1E283F 0%, rgba(20, 27, 46, 0.6) 99.71%)
        border-radius 10px
        width 65px
        height 35px

    .country-label
        font-weight 600
        font-size 12px
        line-height 14px

    // fix for safari
    .country-icon-wrapper
        height 20px
        width 20px
        margin-left 5px

    .country-icon
        height 21px
        width 22px
        max-width 22px
</style>
