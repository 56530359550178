<template>
  <PushNotificationAbstract>
    <template #default="{ showed, loading, bonusAmount, onSubscribe, onRefuse, text, withBonus }">
      <div
        v-if="showed"
        :class="$style.pushNotificationContainer"
      >
        <div :class="$style.pushNotificationBlock">
          <div :class="$style.pushNotificationTop">
            <div :class="$style.pushNotificationContent">
              <div :class="$style.pushNotificationIndicator" />
              <div>
                <div :class="$style.pushNotificationTitle">
                  {{ text.title }}
                </div>
                <template v-if="withBonus">
                  <Spinner
                    v-if="loading"
                    size="sm"
                  />
                  <div
                    v-else
                    :class="$style.pushNotificationAmount"
                  >
                    +{{ bonusAmount }}
                  </div>
                </template>
                <div :class="$style.pushNotificationDescription">
                  {{ text.description }}
                </div>
              </div>
            </div>
            <div :class="$style.pushNotificationContentRight">
              <VPicture
                :class="$style.pushNotificationContentImg"
                :picture="{
                  png: $options.giftWithCoinsImage,
                  webp: $options.giftWithCoinsImageWebp,
                }"
              />
            </div>
          </div>
          <div :class="$style.pushNotificationButtons">
            <VButton
              theme="green"
              size="lg"
              wide="wide"
              @click="onSubscribe"
            >
              {{ text.subscribe }}
            </VButton>
            <VButton
              theme="secondary"
              size="lg"
              wide="wide"
              @click="onRefuse"
            >
              {{ text.refuse }}
            </VButton>
          </div>
        </div>
      </div>
    </template>
  </PushNotificationAbstract>
</template>

<script>
import giftWithCoinsImageWebp from '@/assets/images/gift-with-coins.png?format=webp,sizes[]=300,sizes[]=150';
import giftWithCoinsImage from '@/assets/images/gift-with-coins.png?sizes[]=300,sizes[]=150';
import PushNotificationAbstract from '@/components/PushNotificationAbstract';
import Spinner from '@/components/Spinner.vue';
import VButton from '@/components/VButton.vue';
import VPicture from '@/components/VPicture.vue';

export default {
    name: 'PushNotification',

    components: {
        VPicture,
        Spinner,
        VButton,
        PushNotificationAbstract,
    },

    giftWithCoinsImage,
    giftWithCoinsImageWebp,
};
</script>

<style lang="stylus" module>
$widthContentRight = 113px
$amountFontSize = 24
$titleFontSize = 13
$descriptionFontSize = 12

.pushNotificationContainer
    position sticky
    top $header-bottom-height
    left 10px
    z-index 1000
    transform translateZ(0px)

.pushNotificationTop
    display flex
    align-items center
    min-height 51px

.pushNotificationBlock
    position absolute
    top 10px
    left 10px
    padding 15px
    background radial-gradient(100% 400% at 0% 0%, alpha(#0ee023, 0.12) 0%, alpha(#0ee023, 0) 100%),
      linear-gradient(78.89deg, #1E283F 0%, #141B2E 100%)
    border-radius 16px
    overflow hidden

    @media all and (min-width 1920px)
        left calc((100vw - 1920px) / 2 + 10px)

.pushNotificationContent
    display flex
    position relative
    padding 0 (10px + $widthContentRight) 0 10px
    align-self flex-start

.pushNotificationIndicator
    position absolute
    width 4px
    top 0
    bottom 0
    left -15px
    border-top-right-radius 45px
    border-bottom-right-radius 45px
    background linear-gradient(to left top, #009746 0%, #38C172 100%)

.pushNotificationContentRight
    width $widthContentRight
    position absolute
    right 14px
    top 5px

.pushNotificationContentImg
    user-select none
    pointer-events none

.pushNotificationButtons
    margin-top 15px
    display grid
    grid-gap 10px
    grid-template-columns 1fr 1fr

.pushNotificationTitle
    font-family $ff-heading
    font-weight 600
    font-size ($titleFontSize)px
    line-height (16/$titleFontSize)
    letter-spacing 0.6px
    text-shadow 0 6px 22px alpha(#da8805, .3)
    white-space pre-line

.pushNotificationAmount
    font-family $ff-heading
    font-weight 900
    font-size ($amountFontSize)px
    line-height (29/$amountFontSize)
    text-shadow 0 6px 22px alpha(#da8805, .3)
    background linear-gradient(to right bottom, #FBC709, #FF8500)
    -webkit-background-clip text
    background-clip text
    -webkit-text-fill-color transparent
    margin-top 5px
    display inline-block

.pushNotificationDescription
    font-size ($descriptionFontSize)px
    line-height (14/$descriptionFontSize)
    white-space pre-line
    margin-top 5px
    color alpha(#fff, .7)
</style>
