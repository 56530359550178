<template lang="pug">
.CasinoGameToolbarPanel(
    v-if="showToolbarPanel"
    @click="toggleFullscreen"
)
     VIcon(:icon="fullscreenIcon")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';
import { isToolbarForCasinoGameRequired } from '@/utils/casinoGames';

import ROUTE_NAME from '@desktop/constant/routeName';

export default {
    name: 'CasinoGameToolbarPanel',

    components: {
        VIcon,
    },

    computed: {
        ...mapGetters(['fullscreen']),

        showToolbarPanel() {
            return this.$route.name === ROUTE_NAME.CASINO_GAME && isToolbarForCasinoGameRequired(this.$route.params.id);
        },

        fullscreenIcon() {
            return this.fullscreen ? 'fullscreen-close' : 'fullscreen-open';
        },
    },

    methods: {
        ...mapMutations(['setFullscreen']),

        toggleFullscreen() {
            this.setFullscreen(!this.fullscreen);
        },
    },
};
</script>

<style lang="stylus" scoped>
.CasinoGameToolbarPanel
    position fixed
    right 30px
    bottom 15px
    width 34px
    height 34px
    background-color #2B3843
    border-radius 4px
    cursor pointer
    display flex
    justify-content center
    align-items center
    z-index 999
</style>
