<template>
  <RouterLink
    :to="{ name: $options.ROUTE_NAME.FREE_MONEY }"
    :class="$style.root"
  >
    <span :class="$style.text">Free Money!</span>
    <span
      :class="$style.image"
      :style="{ backgroundImage: `url(${$options.FreeMoneyLinkImage})` }"
    />
    <VIcon
      :class="$style.arrow"
      :size="9"
      icon="arrow-right"
    />
  </RouterLink>
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';

import FreeMoneyLinkImage from '@desktop/assets/images/free-money-link-image.png?sizes[]=120';
import ROUTE_NAME from '@desktop/constant/routeName';

export default {
    components: {
        VIcon,
    },

    ROUTE_NAME,
    FreeMoneyLinkImage,
};
</script>

<style lang="stylus" module>
.root
    padding 5px 28px 6px 50px
    border-radius 50px
    background-image linear-gradient(285.39deg, #FFB800 -4.36%, #FFF173 115.87%)
    font-weight 600
    font-size 11px
    position relative

.text
    color #090F1E

.image
    display block
    width 60px
    height 33px
    position absolute
    bottom 0
    left -6px
    background-size 100%
    background-repeat no-repeat
    pointer-events none

.arrow
    position absolute
    top 0
    right 10px
    bottom 0
    margin auto
    color alpha(#000, .3)
</style>
