<template lang="pug">
VDropdown.user-menu(
    angle
    align="center"
)
    template(#trigger="props")
        .user-menu__avatar(:class="userMenuAvatarClass")
            HeaderUserAvatar
            .user-menu__toggle
                VIcon.user-menu-toggle__icon(
                    :icon="props.isActive ? 'times' : 'dots'"
                    :key="props.isActive"
                )

    VDropdownItem(
        v-for="(item, index) in menu"
        :key="index"
        :route="item.route"
        nowrap
        @click="item.action"
    )
        span.user-menu__item {{ item.name }}
        span.user-menu__badge(v-if="item.badge") {{ item.badge }}
</template>

<script>
import { mapGetters } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';
import VDropdown from '@/components/VDropdown/VDropdown.vue';
import VDropdownItem from '@/components/VDropdown/VDropdownItem.vue';
import {
    HEADER_INVEST,
    ONE_WIN_COIN,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
} from '@/constant/constants';

import {
    MODAL_DETAILING, MODAL_LOGOUT,
    MODAL_PROFILE,
    MODAL_PROMOCODE,
    MODAL_WITHDRAWAL,
    MODAL_VIP_DOMAIN,
    MODAL_LOYALTY,
} from '@desktop/constant/modalName';
import HeaderUserAvatar from '@desktop/layouts/components/Header/HeaderUserAvatar.vue';

export default {
    name: 'HeaderUserDropdown',

    components: {
        HeaderUserAvatar,
        VDropdown,
        VDropdownItem,
        VIcon,
    },

    inject: ['$modal'],

    computed: {
        ...mapGetters([
            'showVipDomain',
            'isReducedFunctionalityPoker',
            'isReducedFunctionalityInvest',
        ]),

        userMenuAvatarClass() {
            return {
                'invest-theme': this.isInvestPage,
            };
        },

        isInvestPage() {
            return this.customHeader === HEADER_INVEST;
        },

        customHeader() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_HEADER_BOTTOM];
        },

        menu() {
            const text = this.$store.getters.lang.header?.['user-box'] || {};
            const list = [
                {
                    name: this.$store.getters.lang.promocode.title,
                    action: () => this.$modal.open(MODAL_PROMOCODE),
                },
                {
                    name: text.withdrawal,
                    action: () => this.$modal.open(MODAL_WITHDRAWAL),
                },
                {
                    name: ONE_WIN_COIN,
                    action: () => this.$modal.open(MODAL_LOYALTY),
                    badge: 'NEW',
                },
                {
                    name: text.bets_history,
                    route: { path: '/bets-history' },
                    hidden: this.isReducedFunctionalityPoker || this.isReducedFunctionalityInvest,
                    action: () => {},
                },
                {
                    name: text.settings,
                    action: () => {
                        this.$modal.open(MODAL_PROFILE);
                    },
                },
                {
                    name: text.detailing,
                    action: () => this.$modal.open(MODAL_DETAILING),
                },
                ...this.showVipDomain ? [{
                    name: text.vipDomain,
                    action: () => this.$modal.open(MODAL_VIP_DOMAIN, { saveRoute: false }),
                }] : [],
                {
                    name: text.exit,
                    action: () => this.$modal.open(MODAL_LOGOUT),
                },
            ];

            return list.filter(({ hidden }) => !hidden);
        },
    },
};
</script>

<style lang="stylus" scoped>
.user-menu
    margin-right 10px

.user-menu__avatar
    display flex
    align-items center
    background #1f2841
    border-radius 20px 8px 8px 20px
    padding 3px 8px 3px 3px

    &.invest-theme
        backdrop-filter blur(5px)
        background-color alpha(#4e5d86, .3)

.user-menu__toggle
    display flex
    align-items center
    justify-content center
    margin-left 5px
    width 10px

.user-menu__item
    text-transform capitalize

.user-menu__badge
    background linear-gradient(180deg, #31BC69 0%, #089E4E 100%)
    border-radius 4px
    padding 3.5px 6px
    font-weight bold
    font-size 8px
    line-height 8px
    letter-spacing -0.12px
    color #fff
    margin-left 5px

.user-menu :deep(.dropdown-menu)
    left 16px
</style>
