<template>
  <div :class="$style.root">
    <VButton
      v-if="$store.getters.supportNumber"
      :class="$style.call"
      @click="modal.open(MODAL_CALL_SUPPORT)"
    >
      <VIcon icon="phone" />
    </VButton>
    <VButton
      :class="$style.text"
      disabled
    >
      24/7
    </VButton>
    <SupportChatTrigger>
      <template #default="{ icon, open, spin }">
        <VButton
          :class="$style.chat"
          @click="open"
        >
          <VIcon
            :icon="icon"
            :spin="spin"
          />
        </VButton>
      </template>
    </SupportChatTrigger>
  </div>
</template>

<script setup>
import VIcon from '@/components/Icons/VIcon.vue';
import SupportChatTrigger from '@/components/SupportChatTrigger.vue';
import VButton from '@/components/VButton.vue';
import { useModal } from '@/hooks/useModalProvider';

import { MODAL_CALL_SUPPORT } from '@desktop/constant/modalName';

const modal = useModal();
</script>

<style lang="stylus" module>
.root
    button-group()
    bottom 15px
    font-size 10px
    position fixed
    right 15px
    z-index 999

.call
    background-image linear-gradient(112deg, #108de7 0%, #2972d6 100%)

.text
    +repeat-selector(3)
        background-image linear-gradient(112deg, #2972d6 0%, #2662cc 100%)
        opacity 1

.chat
    background-image linear-gradient(112deg, #2662cc 0%, #2662cc 100%)
</style>
