<template lang="pug">
.loading-dots
    .loading-dots__dot
    .loading-dots__dot
    .loading-dots__dot
</template>

<script>
export default {
    name: 'LoadingDots',
};
</script>

<style scoped lang="stylus">
@keyframes dot-keyframes
    0%
        opacity .4
        transform scale(1, 1)

    50%
        opacity 1
        transform scale(1.2, 1.2)

    100%
        opacity .4
        transform scale(1, 1)

.loading-dots
    text-align center
    width 100%
    color #fff

.loading-dots__dot
    animation dot-keyframes 1.5s infinite ease-in-out
    color inherit
    background-color currentColor
    border-radius 50%
    display inline-block
    height 5px
    width 5px
    margin 0 1px
    will-change transform

    &:nth-child(2)
        animation-delay .5s

    &:nth-child(3)
        animation-delay 1s
</style>
