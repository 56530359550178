<template lang="pug">
.translation-container(:class="{ active: isDrag }")
    MatchTranslation.translation-container-iframe(
        :url="currentTranslation"
        :key="currentTranslation"
    )
    .translation-container-overlay
    .translation-container-drag(
        @mousedown="mousedown"
        @mouseup="mouseup"
    )
        VIcon(icon="arrows" square)

    .translation-container-close(@click="closeTranslation")
        VIcon(icon="times" square)
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';

import MatchTranslation from '@desktop/pages/components/Bets/MatchTranslation.vue';

const BOUND = 10;
const OFFSET = 2;

export default {
    name: 'TranslationContainer',

    components: {
        MatchTranslation,
        VIcon,
    },

    data: () => ({
        prevMouseX: 0,
        prevMouseY: 0,
        startX: 5,
        startY: 5,
        isDrag: false,
    }),

    computed: {
        ...mapGetters({
            currentTranslation: 'bets/currentTranslation',
        }),
    },

    mounted() {
        this.setPosition(document.body.clientWidth, 0);

        document.addEventListener('mousemove', this.mousemove);
        document.addEventListener('mouseup', this.mouseup);
    },

    beforeDestroy() {
        document.removeEventListener('mousemove', this.mousemove);
        document.removeEventListener('mouseup', this.mouseup);
    },

    methods: {
        ...mapMutations('bets', ['setCurrentTranslation']),

        closeTranslation() {
            this.setCurrentTranslation();
        },

        mousedown(e) {
            this.isDrag = true;
            const { x, y } = this.getPosition();

            this.startX = x;
            this.startY = y;

            this.prevMouseX = e.clientX;
            this.prevMouseY = e.clientY;
        },

        mouseup() {
            this.isDrag = false;
        },

        mousemove(e) {
            if (!this.isDrag) return;

            const mouseX = e.clientX;
            const mouseY = e.clientY;

            const x = this.startX + (mouseX - this.prevMouseX);
            const y = this.startY + (mouseY - this.prevMouseY);

            this.setPosition(x, y);
        },

        setPosition(x1, y1) {
            const { x, y } = this.fixCollision(x1, y1);

            this.$el.style.left = `${x}px`;
            this.$el.style.top = `${y}px`;
        },

        getPosition(el = this.$el) {
            return {
                x: Number.parseInt(el.style.left, 10),
                y: Number.parseInt(el.style.top, 10),
            };
        },

        fixCollision(coordinateX, coordinateY) {
            const screenWidth = document.body.clientWidth;
            const screenHeight = document.body.clientHeight;
            const width = this.$el.clientWidth;
            const height = this.$el.clientHeight;

            let x = Math.floor(coordinateX / OFFSET) * OFFSET;
            let y = Math.floor(coordinateY / OFFSET) * OFFSET;

            if (screenWidth - BOUND < x + width) {
                x = screenWidth - BOUND - width;
            }

            if (BOUND > x) {
                x = BOUND;
            }

            if (screenHeight - BOUND < y + height) {
                y = screenHeight - BOUND - height;
            }

            if (BOUND > y) {
                y = BOUND;
            }

            return { x, y };
        },
    },
};
</script>

<style lang="stylus" scoped>
    .translation-container
        user-select none
        position fixed
        width 500px
        background-color transparent
        z-index 1001
        padding 0 7px 7px 0
        overflow hidden
        resize horizontal

        .translation-container-iframe
            border 1px solid white
            background-color #0e1014

        .translation-container-drag
        .translation-container-close
            position absolute
            top: 0
            opacity .2
            padding 10px
            color white
            transition opacity .3s
            z-index 3
            font-size 18px

            &:hover
                opacity 1

        .translation-container-close
            right 7px
            cursor pointer

        .translation-container-drag
            right 45px
            cursor: grab

        .translation-container-overlay
            position absolute
            width 100%
            height 100%
            top 0
            left 0
            display none

        &.active
            cursor: grabbing

            .translation-container-overlay
                display block

            .translation-container-drag
                cursor: grabbing

</style>
