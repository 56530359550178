<template lang="pug">
section.bonus-info
    .bonus-info__header
        .bonus-info__title {{ text['amount-header'] }}
    .bonus-info__items
        .bonus-info__item
            .bonus-info__item-title.bonus-info__item-title--bonus
                | {{ text.bonusBetsTitle }}
            .bonus-info__item-message
                | {{ bonusAmount }}
        .bonus-info__item
            .bonus-info__item-title.bonus-info__item-title--casino
                | {{ text.bonusCasinoTitle }}
            .bonus-info__item-message
                | {{ text.bonusCasinoDescription }}
</template>

<script>
import { mapGetters } from 'vuex';

import VButton from '@/components/VButton.vue';
import { formatString } from '@/plugins/LanguageTools';

export default {
    components: {
        VButton,
    },

    computed: {
        ...mapGetters(['offer/get']),

        text() {
            return this.$store.getters.lang.promo.bonus;
        },

        bonusAmount() {
            const {
                bet_max_coefficient: betMaxCoefficient,
                bet_min_coefficient: betMinCoefficient,
                bonus_percent: bonusPercent,
            } = this['offer/get'](1) || {};

            // coefficient condition
            const buildCoefficientDescription = () => {
                // range
                if (+betMaxCoefficient > 0 && +betMinCoefficient > 0) {
                    return formatString(
                        this.text['amount-description-coefficient-range'],
                        betMinCoefficient,
                        betMaxCoefficient,
                    );
                }

                // only min
                if (+betMinCoefficient > 0) {
                    return formatString(
                        this.text['amount-description-coefficient-min'],
                        betMinCoefficient,
                    );
                }

                // only max
                // if (+bet_max_coefficient > 0)
                return formatString(
                    this.text['amount-description-coefficient-max'],
                    betMaxCoefficient,
                );
            };

            return formatString(
                this.text['amount-description'],
                buildCoefficientDescription(),
                bonusPercent * 100,
            );
        },
    },
};
</script>

<style lang="stylus" scoped>
.bonus-info
    padding 10px 15px
    min-width 220px

    .bonus-info__header
        display flex
        align-items center
        justify-content space-between
        margin-bottom 5px

    .bonus-info__title
        color #000
        font-size 13px
        font-weight bold

    .bonus-info__item
        padding 10px 0

        &:not(:first-child)
            border-top 1px solid alpha(#090f1e, .2)

        &:last-child
            padding-bottom 0

        .bonus-info__item-title
            font-weight bold
            padding-left 17px
            background-size auto 90%
            background-repeat no-repeat
            background-position 0 50%
            margin-bottom 10px

            &.bonus-info__item-title--casino
                background-image url('~@/assets/images/emoji-slots.png')

            &.bonus-info__item-title--bonus
                background-image url('~@/assets/images/emoji-soccer.png')

    .bonus-info__item-message
        color #000
        padding-left 5px
        font-size 12px
        font-weight 400
        width 190px
        white-space normal

    .bonus-info-link
        width 100%
        display inline-block

    .bonus-info-button
        margin-top 10px
        width 100%
</style>
